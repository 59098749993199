class DropdownQuestion extends Taker.BaseQuestion
  @icon: "fa fa-caret-square-o-down"
  @display_name: "Dropdown Select"
  @question_type: "select"

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.DropdownQuestion = DropdownQuestion
