import * as _ from "lodash"
class MediaUploadQuestion extends Taker.BaseQuestion {
  static icon = "fa fa-check-square-o"
  static display_name = "Media Upload"
  static question_type = "media_upload"

  validate(): boolean {
    const fileOnServerStatuses = ["attached", "uploaded", "processing", "processed", "attaching"]

    // @ts-ignore
    if (window.SurveyHelper.isBuilding || !this.is_visible) {
      return true
    }
    this.error_messages = []
    let hasErrors = false
    for (let ans of this.selected_answers) {
      const a = ans
      a.errors = []

      // - VALIDATE COMMENTS REQUIRED
      if (this.validations?.find(v => v.type == "required")) {
        if (this.config.require_comments && !a.comments) {
          a.errors.push(languageHelper[window.SurveyHelper.locale]["COMMENTS_REQUIRED_MSG"])
          hasErrors = true
        }

        // @ts-ignore
        const qmr = this.qmrs[a.questionnaire_media_response_id]

        // VALIDATE MEDIA PRESENCE
        // if it's an image, its gotta be attached
        // otherwise, it can just 'exist' on the server

        if (!qmr || !fileOnServerStatuses.includes(qmr.status)) {
          // console.log("qmr", qmr)
          // console.log("invalid answer", a)
          a.errors.push(languageHelper[window.SurveyHelper.locale]["MEDIA_REQUIRED_MSG"])
          hasErrors = true
        }

        if (!a.media_url) {
          // console.log("no media url!!!", a)
          a.errors.push(languageHelper[window.SurveyHelper.locale]["MEDIA_REQUIRED_MSG"])
          hasErrors = true
        }
      } else {
        // THIS SHOULD RUN 100% OF THE TIME, EVEN IF QUESTION IS NOT REQUIRED
        // * Validate that there is no 'in-progress' upload happen if the question is optional, the user could lose data
        // @ts-ignore
        const qmr = this.qmrs[a.questionnaire_media_response_id]
        if (qmr && qmr.status !== "waiting" && !fileOnServerStatuses.includes(qmr.status)) {
          a.errors.push(languageHelper[window.SurveyHelper.locale]["MEDIA_REQUIRED_MSG"])
          hasErrors = true
        }
      }
    }
    if (hasErrors) {
      this.error_messages.push({ type: "MULTIPLE_ERRORS_MSG" })
    }
    // @ts-ignore
    this.onValidate && this.onValidate(hasErrors)
    return !hasErrors
  }

  constructor(data) {
    const init_data = {}
    _.merge(init_data, data)
    super(init_data)
  }
}
MediaUploadQuestion.initClass()

window.Taker.MediaUploadQuestion = MediaUploadQuestion
