import * as _ from "lodash"

associationCtrl = (
  $scope
  $document
  $timeout
) ->
  "ngInject"

  $scope.current_assoc_index = 0
  start_time = null
  question = null
  auto_advance_timeout = null
  $document.unbind 'keypress', bound_function

  init = (q) ->
    question = q
    $scope.unRegisterRequiredAction(question.cid)
    $scope.registerRequiredAction(question.cid)
    start_time = Date.now()

  start_timer = ->
    if(question.config.auto_advance)
      vm.started = true
      start_time = Date.now()
      auto_advance_timeout = $timeout(() ->
        process_answer(null)
      , question.config.auto_advance_time * 1000)

  process_answer = (answer) ->
    if question.config.auto_advance && !vm.started
      start_timer()
      return
    $timeout.cancel(auto_advance_timeout)
    end_time = Date.now()
    time = end_time - start_time
    current_answer = question.answers[$scope.current_assoc_index]
    if !_.find question.selected_answers, { id: current_answer.id }
      question.selected_answers.push({id: current_answer.id, time: time, score: answer, answered_at: Date.now()})
      question.recordAnswer()
    if question.selected_answers.length == question.answers.length
      $scope.unRegisterRequiredAction(question.cid)
      $document.unbind 'keypress', bound_function
      $scope.nextPage()
    else
      if(question.config.auto_advance)
        auto_advance_timeout = $timeout(() ->
          process_answer(null)
        , question.config.auto_advance_time * 1000)
    $scope.current_assoc_index = $scope.current_assoc_index + 1
    start_time = Date.now()

  bound_function = (e) ->
    obj = _.find question.config.key_maps, {key: String.fromCharCode(e.charCode)}
    if obj
      $scope.$apply(->
        process_answer(obj.value)
      )

  $document.bind 'keypress', bound_function

  vm =
    init: init
    start_timer: start_timer
    started: false

  $scope.vm = vm

export default associationCtrl
