import * as _ from "lodash"

class ANotA extends Taker.DiscrimQuestion
  @icon: "fa fa-dot-circle-o"
  @display_name: "A Not A"
  @question_type: "discrim:anota"
  @file_name: "anota"

  recordAnswer: (answer) ->
    @selected_answers = [{id: answer.id, answered_at: Date.now()}]
    @publishAnswerSelectedEvent()

  determineCorrectness: (surveyHelper) ->
    selected_answer_id = @selected_answers?[0]?.id?.toString()
    answer_source = _.find(@answers, (ans) -> ans.id.toString() == selected_answer_id)
    should_be_same = (@config.reference_sample_id == surveyHelper?.samples?[0]?.id) # TODO: We need to look up the reference sample and see if the tracking codes match
    if should_be_same
      @is_correct = answer_source.value.includes("same") # same, same_sure, same_guess
    else
      @is_correct = answer_source.value.includes("different")  # different, differnt_sure, different_guess

  constructor: (data) ->
    super data

window.Taker.ANotA = ANotA
