inviteMyredjadeCtrl = (
  $scope
  $state
  $sce
  $q
  $timeout
  $injector
  $stateParams
  appInfo
  languageHelper
  surveyDataAdapter
  localStorageService
) ->
  "ngInject"


  $scope.model = {
    accepted_consent: false,
    reached_end: false,
    clicked_decline: false,
    loading: true
  }

  if ($state.current.data)
    $scope.from_previewer = $state.current.data.from_previewer
    $scope.previewUrl = "/previewer/?project_id=#{localStorageService.get("previewer_project_id")}" +
      "&sensory_test_id=#{localStorageService.get("previewer_sensory_test_id")}&event_id=#{localStorageService.get("previewer_event_id")}"

  $scope.languageHelper = languageHelper
  $scope.appinfo = appInfo

  init =  ->
    $scope.loading = true
    promises = []
    promises.push(surveyDataAdapter.fetchCurrentParticipant(lite: true))
    promises.push(surveyDataAdapter.fetchMeta())
    $q.all(promises).then ((responses) ->
      $scope.locale = responses[0]['locale'] || responses[0]['preferred_language']
      $scope.meta = responses[1]
      setScreenDefaults()
      $scope.loading = false
    )

  setScreenDefaults = ->
    screens = $scope.meta.screens
    locale = $scope.locale
    await languageHelper.load(locale)
    if !screens[locale]
      screens[locale] = {}
    if !screens[locale].STATION_KIOSK_INVITE_MYREDJADE_PROMPT_HTML
      screens[locale].STATION_KIOSK_INVITE_MYREDJADE_PROMPT_HTML = languageHelper[locale].STATION_KIOSK_INVITE_MYREDJADE_PROMPT_HTML
    if !screens[locale].STATION_KIOSK_INVITE_MYREDJADE_ACCEPT
      screens[locale].STATION_KIOSK_INVITE_MYREDJADE_ACCEPT = languageHelper[locale].STATION_KIOSK_INVITE_MYREDJADE_ACCEPT
    if !screens[locale].STATION_KIOSK_INVITE_MYREDJADE_DECLINE
      screens[locale].STATION_KIOSK_INVITE_MYREDJADE_DECLINE = languageHelper[locale].STATION_KIOSK_INVITE_MYREDJADE_DECLINE
    $scope.$apply()

  $scope.renderHtml = (html_code) ->
    $sce.trustAsHtml(html_code)

  $scope.joinDatabase = (accepted) ->
    surveyDataAdapter.invite_myredjade(accepted, $stateParams.participant_id).then ->
      window.location.reload()
    , (res) ->
      window.location.reload()

  init()
  return



export default inviteMyredjadeCtrl

