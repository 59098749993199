import * as angular from "angular"


ui_questionnaire = angular.module('redjade.survey_taker.ui.questionnaire', [

])
  .controller('surveyOrchestratorCtrl', require("./surveyOrchestrator.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/questionnaire/questionnaire_resubmit.html', require("./questionnaire_resubmit.html"))
    $templateCache.put('ui/questionnaire/questionnaire.html', require("./questionnaire.html"))
  )




export default ui_questionnaire
