pageElementCtrl = (
  $scope
) ->
  "ngInject"


  $scope.init = (question) ->
    question['has_been_seen'] = true
    question.publishAnswerSelectedEvent() if question.publishAnswerSelectedEvent


export default pageElementCtrl

