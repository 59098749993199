toTrusted = (
  $sce
) ->
  "ngInject"

  (text) ->
    return $sce.trustAsHtml(text)


export default toTrusted

