class DemographicQuestion extends Taker.BaseQuestion
  @icon: "fa fa-dot-circle-o"
  @display_name: "Demographic Question"
  @question_type: "demographic"

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.DemographicQuestion = DemographicQuestion
