class RankingQuestion extends Taker.BaseQuestion
  @icon: "fa fa-sort-numeric-asc"
  @display_name: "Ranking"
  @question_type: "ranking"

  validate: (sample_count) ->
    @error_messages = []
    if @validations && @is_visible
      _.each @validations, (rule) =>
        if rule.type is "required"
          if @selected_answers.length < 1 || sample_count != @selected_answers.length
            @error_messages.push {type: "required"}

        if rule.type is "range"
          @error_messages.push {type: "min", value: "#{rule.min}"}  if rule.min and @selected_answers.length < parseInt(rule.min)
          @error_messages.push {type: "max", value: "#{rule.max}"}  if rule.max and @selected_answers.length > parseInt(rule.max)

    @error_messages.length is 0

  recordAnswer: (sample) ->
    @selected_answers = []  unless @selected_answers
    f = _.filter @selected_answers, { serving_sample_id: sample.serving_sample_id }
    if f.length == 0
      @selected_answers.push { position: @selected_answers.length + 1, serving_sample_id: sample.serving_sample_id, sample_id: sample.id, sample_position: sample.sequence_position, answered_at: Date.now() }
    @publishAnswerSelectedEvent()

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.RankingQuestion = RankingQuestion
