class TwoOfFive extends Taker.DiscrimQuestion
  @icon: "fa fa-check-square-o"
  @display_name: "Two of Five"
  @question_type: "discrim:two_of_five"
  @file_name: "two_of_five"
  @has_multiple_selections: true

  recordAnswer: (sample) ->
    if (existing = _.find(@selected_answers, (ans) -> ans.serving_sample_id == sample.serving_sample_id))
      @selected_answers = _.without @selected_answers, existing
    else
      @selected_answers.push({serving_sample_id: sample.serving_sample_id, sample_id: sample.id, answered_at: Date.now()})

    @validate() if @error_messages and @error_messages.length > 0
    @publishAnswerSelectedEvent()

  isAnswerSelected: (sample) ->
    _.any(@selected_answers, (ans) -> ans.serving_sample_id == sample.serving_sample_id)

  determineCorrectness: (surveyHelper) ->
    if @selected_answers.length != 2
      @correct = false
    else
      samples = surveyHelper.samples

      grouped_samples = _.groupBy(samples, 'id')
      correct_sample = _.find(grouped_samples, (group) -> group.length == 2)[0]

      @is_correct = _.all(@selected_answers, (answer) ->
        answer.sample_id == correct_sample.id
      )

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.TwoOfFive = TwoOfFive
