class Hexad extends Taker.DiscrimQuestion
  @icon: "fa fa-check-square-o"
  @display_name: "Hexad"
  @question_type: "discrim:hexad"
  @file_name: "hexad"

  recordAnswer: (sample) ->
    if (existing = _.find(@selected_answers, (ans) -> ans.serving_sample_id == sample.serving_sample_id))
      @selected_answers = _.without @selected_answers, existing
    else
      @selected_answers.push({serving_sample_id: sample.serving_sample_id, sample_id: sample.id || sample.sample_id, answered_at: Date.now()})
    @validate() if @error_messages and @error_messages.length > 0
    @publishAnswerSelectedEvent()

  recordIgnore: (sample) ->
    if (existing = _.find(@ignored_answers, (ans) -> ans.serving_sample_id == sample.serving_sample_id))
      @ignored_answers = _.without @ignored_answers, existing
    else
      @ignored_answers.push({serving_sample_id: sample.serving_sample_id, sample_id: sample.id  || sample.sample_id, answered_at: Date.now()})
    @validate() if @error_messages and @error_messages.length > 0
    @publishAnswerSelectedEvent()

  clearAnswer: (sample) ->
    if (existing = _.find(@ignored_answers, (ans) -> ans.serving_sample_id == sample.serving_sample_id))
      @ignored_answers = _.without @ignored_answers, existing
    if (existing = _.find(@selected_answers, (ans) -> ans.serving_sample_id == sample.serving_sample_id))
      @selected_answers = _.without @selected_answers, existing
    @validate() if @error_messages and @error_messages.length > 0
    @publishAnswerSelectedEvent()

  dragStartCallback: (event, ui, sample) ->
    @dragging_sample = sample

  dropAnswerCallback: (event, ui, stuff) ->
    @clearAnswer(@dragging_sample)
    @recordAnswer(@dragging_sample)

  dropIgnoreCallback: (event, ui, stuff) ->
    @clearAnswer(@dragging_sample)
    @recordIgnore(@dragging_sample)

  dropClearCallback: (event, ui, stuff) ->
    @clearAnswer(@dragging_sample)

  isAnswerSelected: (sample) ->
    _.any(@selected_answers, (ans) -> ans.serving_sample_id == sample.serving_sample_id)

  isAnswerIgnored: (sample) ->
    _.any(@ignored_answers, (ans) -> ans.serving_sample_id == sample.serving_sample_id)

  determineCorrectness: (surveyHelper) ->
    if @selected_answers.length == 3
      @is_correct = (@selected_answers[0].sample_id == @selected_answers[1].sample_id) && ( @selected_answers[0].sample_id == @selected_answers[2].sample_id)
    else
      @is_correct = false

  constructor: (data) ->
    init_data = {
      ignored_answers: []
    }
    _.merge(init_data, data)
    super init_data


window.Taker.Hexad = Hexad