import * as _ from "lodash"
import * as angular from 'angular'
import noUiSlider from 'nouislider'
# import 'nouislider/src/nouislider.less'
import 'nouislider/distribute/nouislider.css'

slider = (
) ->
  "ngInject"
  sliders = {}

  restrict: "E"
  scope:
    start: "="
    step: "="
    end: "="
    samples: "="
    ngModel: "="
    hideHandle: "="
    orientation: "="
    useSampleColors: "="
    direction: "="
    onValueChange: "&"
    question: "="

  link: (scope, element, attrs) ->
    parsedValue = undefined
    sliders[attrs.id] = angular.element(element)[0]
    callback = (if scope.callback then scope.callback else "slide")

    parsedValue = null
    noUiSlider.create sliders[attrs.id],
      start: [scope.ngModel || scope.start]
      step: parseFloat(scope.step || 1)
      range:
        min: [parseFloat(scope.start)]
        max: [parseFloat(scope.end)]
      orientation: scope.orientation || 'horizontal'
      direction: scope.direction || 'ltr'
    sliders[attrs.id].noUiSlider.on 'slide', (values, handle)->
      parsedValue = parseFloat(values[handle])

      angular.element(sliders[attrs.id]).removeClass "no-handle"
      phase = scope.$root.$$phase
      if(phase == '$apply' || phase == '$digest')
        scope.ngModel = parsedValue
      else
        scope.$apply ->
          scope.ngModel = parsedValue

    if scope.hideHandle
      angular.element(sliders[attrs.id]).addClass("no-handle") unless scope.ngModel || scope.ngModel == 0

    if scope.useSampleColors
      tick = angular.element(sliders[attrs.id]).find(".noUi-handle")
      tick.css('background-color', scope.samples[0]?.line_color)

    angular.element(sliders[attrs.id]).find(".noUi-base").on('click', (e) ->
      # i honestly dont know why this works but catching this click keeps shouldOverrideAnswer from being
      # ignored when clicking multiple times in the same spot on the slider
    )

    scope.$watch("ngModel", (newVal, oldVal) ->
      handle = angular.element(sliders[attrs.id].noUiSlider.target).find('.noUi-handle')
      if (newVal == undefined || newVal == null) && scope.hideHandle
        handle.css('display', 'none')
      else
        handle.css('display', 'block')

      if parsedValue || parsedValue == 0
        $(sliders[attrs.id]).val newVal if newVal isnt parsedValue
        if scope.question?.shouldOverrideAnswer && scope.question.shouldOverrideAnswer(newVal)
          val = scope.question.shouldOverrideAnswer(newVal)
          $(sliders[attrs.id]).val(val)
          sliders[attrs.id].noUiSlider.set(val)
          scope.question.current_slider_val = val

        scope.onValueChange()

      if newVal == undefined || newVal == null
        sliders[attrs.id].noUiSlider.reset()
    )

    scope.$watch("end", (newVal, oldVal) ->
      if newVal && !_.isNaN(parseFloat(newVal))
        sliders[attrs.id].noUiSlider.options.range =
            min: [parseFloat(scope.start)]
            max: [parseFloat(scope.end)]
    )

    scope.$watch("start", (newVal, oldVal) ->
      if newVal && !_.isNaN(parseFloat(newVal))
        sliders[attrs.id].noUiSlider.options.range =
            min: [parseFloat(scope.start)]
            max: [parseFloat(scope.end)]
    )

    scope.$watch("step", (newVal, oldVal) ->
      if newVal && !_.isNaN(parseFloat(newVal))
        sliders[attrs.id].noUiSlider.options.step = parseFloat(scope.step or 1)
    )

    scope.$watch("hideHandle", (newVal, oldVal) ->
      if newVal
        angular.element(sliders[attrs.id]).addClass("no-handle") unless scope.ngModel || scope.ngModel == 0
      else
        angular.element(sliders[attrs.id]).removeClass("no-handle")
    )

    scope.$watch("orientation", (newVal, oldVal) ->
      if newVal
        sliders[attrs.id].noUiSlider.options.orientation = newVal
    )

    scope.$watch("direction", (newVal, oldVal) ->
      if newVal
        sliders[attrs.id].noUiSlider.options.direction = newVal
    )

    ignoreSimulatedIOsClick = (type) ->
      $(element)[0].addEventListener(type, (e) ->
        if(e.type == "mousedown" && !e.buttons && !e.touches)
          e.preventDefault()
          e.stopPropagation()
          return false
      , true)
    ignoreSimulatedIOsClick('mousedown')
    ignoreSimulatedIOsClick('mouseup')
    ignoreSimulatedIOsClick('click')
    ignoreSimulatedIOsClick('mousemove')
    return

export default slider
