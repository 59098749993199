class RangeSliderQuestion extends Taker.BaseQuestion
  @icon: "fa fa-arrows-h"
  @display_name: "Line Scale"
  @question_type: "range"

  recordAnswer: (answer) ->
    return if answer == undefined
    if @config.zipper_merge
      @selected_answers = {} if $.isArray(@selected_answers)
      if answer.sample_id && @config.min <= answer?.score <= @config.max
        @selected_answers[answer.sample_id.toString()] = [{score: answer.score, answered_at: Date.now()}]
    else
      @selected_answers = [{score: answer, answered_at: Date.now()}]

    @validate() if @error_messages and @error_messages.length > 0
    @publishAnswerSelectedEvent()

  computePreviousSampleOffset: (element, previous_sample) ->
    width = $(element).width() || 0
    half_width = Math.floor(width / 2.0) # Math.floor hack to prevent infinte digest loop
    left = half_width * -1.0 + 2.0
    leftmost = $(element).offset().left
    rightmost = $(element).offset().left + width
    top = calculateTop(-20, leftmost, rightmost, @previous_sample_groups, previous_sample)
    res = {
      color: previous_sample.line_color,
      left: "#{left}px",
      top: "#{top}px"
    }
    res

  getWidth = () ->
    alert()
     
  calculateTop = (top, left, right, prev_sample_groups, previous_sample) ->
    current_height_group = prev_sample_groups["#{top}"]
    if !current_height_group
      current_height_group = prev_sample_groups["#{top}"] = []
    else
      return top if _.find(current_height_group, (item) -> item.serving_id == previous_sample.serving_id)

    #if (prev_s.left < leftmost_point && leftmost_point < prev_s.right || prev_s.left < rightmost_point && rightmost_point < prev_s.right)
    can_fit_in_height_group = !_.any(current_height_group, (prev_s) ->
      (prev_s.left <= left && left <= prev_s.right) ||   # right side overlap
      (prev_s.left <= right && right <= prev_s.right) || # left side overlap
      (prev_s.left >= left && prev_s.right <= right)     # inbetween
    )
    if can_fit_in_height_group
      current_height_group.push({
        serving_id: previous_sample.serving_id
        left: left
        right: right
      })
      top
    else
      calculateTop(top - 16, left, right, prev_sample_groups, previous_sample)

  constructor: (data) ->
    init_data = {
      previous_sample_groups: { '-20': [] }
    }
    _.merge(init_data, data)
    super init_data


window.Taker.RangeSliderQuestion = RangeSliderQuestion
