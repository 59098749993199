import { QuestionValidation } from "~angular/src/survey_builder/ui/question_configuration_tabs/validationForm.controller"
import * as _ from "lodash"

// decaffeinate wrapped this class and returned it to a new variable. It breaks if you unwrap it.
var BaseQuestion = (function () {
  let answeringTimeout = undefined
  let BaseQuestionClass = class BaseQuestion extends Taker.BaseElement {
    answers: any[]
    selected_answers: any[]
    error_messages: any[]
    validations: QuestionValidation[]
    is_visible: boolean
    is_required: boolean
    is_correct: boolean
    unzipped_questions: any[]
    explanations: string[]
    shuffled?: boolean
    groups?: any[]
    has_been_seen?: boolean
    question_type: string
    updated_at?: string
    static has_multiple_selections = false

    static initClass() {
      answeringTimeout = null
    }

    isDiscrim(): boolean {
      //@ts-ignore
      return this.constructor.is_discrim
    }

    isCorrect() {
      return this.is_correct
    }

    hasMultipleSelections(): boolean {
      //@ts-ignore
      return this.constructor.has_multiple_selections
    }

    hasMedia(locale) {
      return _.any(this.answers, answer =>
        __guard__(
          __guard__(answer.config != null ? answer.config.media : undefined, x1 => x1[locale]),
          x => x.source_url,
        ),
      )
    }

    handleExclusiveResponses(answer) {
      if (answer.config && answer.config.exclusive) {
        //I am adding an exclusive so remove everything else
        return (this.selected_answers = [])
      } else {
        const exclusive_responses = _.filter(this.answers, { config: { exclusive: true } })
        return _.each(exclusive_responses, response => {
          const obj = _.find(this.selected_answers, { id: response.id })
          if (obj) {
            return (this.selected_answers = _.without(this.selected_answers, obj))
          }
        })
      }
    }

    createSelectedAnswer(answer) {
      const obj: Record<string, any> = {
        id: answer.id,
        answered_at: Date.now(),
      }
      if (answer.zipper_answer_id) {
        obj.zipper_answer_id = answer.zipper_answer_id
        obj.sample_id = answer.sample_id
      }
      if (answer.config && answer.config.explanation) {
        if (!obj["text"]) {
          obj["text"] = ""
        }
      }
      return obj
    }

    validate() {
      this.error_messages = []
      if (this.is_visible) {
        if (this.validations) {
          _.each(this.validations, rule => {
            if (rule.type === "required") {
              if (
                this.selected_answers.length < 1 &&
                (!this.config.answers_source ||
                  this.config.answers_source_selected ||
                  (!this.config.answers_source_selected && this.answers.length > 0))
              ) {
                this.error_messages.push({ type: "required" })
              }
            }

            if (rule.type === "selection count") {
              if (rule.min && this.selected_answers.length < parseInt(rule.min)) {
                this.error_messages.push({ type: "min", value: `${rule.min}` })
              }
              if (rule.max && this.selected_answers.length > parseInt(rule.max)) {
                this.error_messages.push({ type: "max", value: `${rule.max}` })
              }
            }

            if (rule.type === "range" && this.selected_answers[0]) {
              if (rule.min && this.selected_answers[0].score < parseInt(rule.min)) {
                this.error_messages.push({ type: "min", value: `${rule.min}` })
              }
              if (rule.max && this.selected_answers[0].score > parseInt(rule.max)) {
                return this.error_messages.push({ type: "max", value: `${rule.max}` })
              }
            }
          })
        }

        _.each(this.selected_answers, ans => {
          if (ans.hasOwnProperty("text") && ans.text.length < 1) {
            return this.error_messages.push({ type: "required" })
          }
        })
      }

      return this.error_messages.length === 0
    }

    zipperValidate(sample_length) {
      _.each(this.unzipped_questions, function (question) {
        question.validate()
      })
      return _.all(this.unzipped_questions, uq => !_.any(uq.error_messages))
    }

    zipperMergeValidate(sample_length) {
      const that = this
      that.error_messages = []
      if (that.is_visible && that.validations) {
        _.each(that.validations, rule => {
          if (rule.type === "required") {
            if (Object.keys(that.selected_answers).length < sample_length) {
              return that.error_messages.push({ type: "required" })
            }
          }
        })
      }

      return that.error_messages.length === 0
    }

    recordAnswer(answer) {
      if (this.hasMultipleSelections()) {
        if (!this.selected_answers) {
          this.selected_answers = []
        }
        const obj = _.find(this.selected_answers, { id: answer.id })
        if (obj) {
          this.selected_answers = _.without(this.selected_answers, obj)
        } else {
          this.handleExclusiveResponses(answer)
          this.selected_answers.push(this.createSelectedAnswer(answer))
        }
      } else {
        if (answer) {
          this.selected_answers = [this.createSelectedAnswer(answer)]
        }
      }

      if (this.error_messages && this.error_messages.length > 0) {
        this.validate()
      }

      this.publishAnswerSelectedEvent()

      if (
        answer &&
        (answer.config != null ? answer.config.explanation : undefined) &&
        this.explanations &&
        this.explanations[answer.id]
      ) {
        this.recordExplanation(answer.id, this.explanations[answer.id])
      }
    }
    recordExplanation(answer_id: number, text: string) {
      const $timeout = this.getInjector().get("$timeout")
      if (answeringTimeout) {
        $timeout.cancel(answeringTimeout)
      }
      return (answeringTimeout = $timeout(() => {
        const answer = _.find(this.selected_answers, { id: answer_id })
        if (answer) {
          answer.text = escapeSpecialChars(text)
          if (answer.zipper_answer_id) {
            const zip_answer = this.findAnswerByID(answer.zipper_answer_id)
            if (!zip_answer.text) {
              zip_answer.text = {}
            }
            zip_answer.text[answer.sample_id] = answer.text
          }
          if (this.error_messages && this.error_messages.length > 0) {
            this.validate()
          }
          return this.publishAnswerSelectedEvent()
        }
      }, 500))
    }

    isAnswerSelected(answer) {
      if (_.find(this.selected_answers, { id: answer.id })) {
        return true
      } else {
        return false
      }
    }

    constructor(data) {
      // @ts-ignore
      const init_data = {
        has_multiple_selections: false,
      }
      _.merge(init_data, data)
      super(init_data)
      this.isDiscrim = this.isDiscrim.bind(this) // @ts-ignore
      this.isCorrect = this.isCorrect.bind(this) // @ts-ignore
      this.hasMultipleSelections = this.hasMultipleSelections.bind(this) // @ts-ignore
      this.hasMedia = this.hasMedia.bind(this)
    }
  }
  BaseQuestionClass.initClass()
  return BaseQuestionClass
})()

window.Taker.BaseQuestion = BaseQuestion

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}

export default BaseQuestion
