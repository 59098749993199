import * as _ from "lodash"

import TextFormatter, {
  TextFormatterPlainJSON,
} from "~angular/src/survey_builder/ui/question_configuration_tabs/validators/TextFormatter"
import textFormatExamples from "~angular/src/survey_builder/ui/question_configuration_tabs/validators/textFormatExamples"

function findInvalidMessageByFormatterName(formatterName: string, locale: string) {
  for (let i = 0; i < textFormatExamples.length; i++) {
    const formatter = textFormatExamples[i]
    if (formatter.name === formatterName) {
      return formatter.multiLangInvalidMessage[locale]
    }
  }
  return ""
}

// decaffeinate wrapped this class and returned it to a new variable. It breaks if you unwrap it, but you can use it like a regular class
const TextQuestion = (function () {
  let answeringTimeout = undefined

  let TextQuestionClass = class TextQuestion extends Taker.BaseQuestion {
    textFormatValidator: TextFormatter | undefined
    hiddenDateObject: Date = null

    static icon: string
    static display_name: string
    static question_type: string

    static initClass() {
      this.icon = "fa fa-font"
      this.display_name = "Text"
      this.question_type = "text"

      answeringTimeout = null
    }

    setAnswerFromDateObject() {
      if (!this.selected_answers || this.selected_answers.length < 1) {
        this.selected_answers = [{}]
      }
      if (this.hiddenDateObject && this.textFormatValidator) {
        this.selected_answers[0].text = this.textFormatValidator.format(this.hiddenDateObject)
      } else {
        this.selected_answers[0].text = ""
      }
      this.recordAnswer(this.selected_answers[0].text)
    }

    setHiddenDateObject() {
      this.hiddenDateObject = this.textFormatValidator?.date(this.selected_answers[0]?.text)
    }

    validate() {
      this.error_messages = []
      if (this.validations && this.is_visible) {
        let text = ""
        this.validations.forEach(rule => {
          // TEXT FORMAT
          if (rule.type == "text_format") {
            if (!this.textFormatValidator) {
              console.error("No formatter selected for text_format validation rule!")
            } else {
              // check for selected answers
              if (this.selected_answers.length > 0 && !!this.selected_answers[0].text) {
                // make sure if date, it is formatted correctly
                text = this.textFormatValidator.format(this.selected_answers[0].text)
                this.textFormatValidator.date(text)
                // validate the text via regex
                if (!this.textFormatValidator.regex.test(text)) {
                  this.pushTextValidationError()
                } else {
                  // important - this makes sure that a date object doesn't slip into the text field
                  this.selected_answers[0].text = text
                }
              } else {
                this.pushTextValidationError()
              }
            }
          }
          // REQUIRED
          if (rule.type === "required") {
            if (
              this.selected_answers.length < 1 ||
              this.selected_answers[0].length < 1 ||
              this.selected_answers[0].text === ""
            ) {
              this.error_messages.push({ type: "required" })
            }
          }
          // LENGTH
          if (rule.type === "length") {
            if (
              rule.min &&
              (!this.selected_answers[0] || this.selected_answers[0].text.length < parseInt(rule.min)) &&
              parseInt(rule.min) > 0
            ) {
              this.error_messages.push({ type: "text_min", value: rule.min })
            }
            if (
              rule.max &&
              this.selected_answers[0] &&
              this.selected_answers[0].text.length > parseInt(rule.max) &&
              parseInt(rule.max) > 0
            ) {
              return this.error_messages.push({ type: "text_max", value: rule.max })
            }
          }
        })
      }

      return this.error_messages.length === 0
    }

    pushTextValidationError() {
      const surveyHelper: any = this.getInjector().get("surveyHelper")
      let value: string = this.textFormatValidator.multiLangInvalidMessage[surveyHelper.locale]
      if (!value) value = findInvalidMessageByFormatterName(this.textFormatValidator.name, surveyHelper.locale)
      if (value) return this.error_messages.push({ type: "text_format", value })
      return this.error_messages.push({ type: "required" })
    }

    recordAnswer(answer: string) {
      const $timeout = this.getInjector().get("$timeout")
      if (answeringTimeout) {
        $timeout.cancel(answeringTimeout)
      }
      return (answeringTimeout = $timeout(() => {
        this.selected_answers = [{ text: escapeSpecialChars(answer), answered_at: Date.now() }]
        if (this.error_messages && this.error_messages.length > 0) {
          this.validate()
        }
        this.publishAnswerSelectedEvent()
      }, 700))
    }

    getTextFormatValidator() {
      return _.find(this.validations, { type: "text_format" })
    }

    loadTextFormatValidator() {
      const textFormatValidator = this.getTextFormatValidator()
      if (textFormatValidator && textFormatValidator.selectedFormatter) {
        this.textFormatValidator = TextFormatter.fromRegexPattern(
          textFormatValidator.selectedFormatter as TextFormatterPlainJSON,
        )
      }
    }

    constructor(data) {
      const init_data = {}
      _.merge(init_data, data)
      super(init_data)
      this.loadTextFormatValidator()
      // @ts-ignore
      window.questions ||= []
      // @ts-ignore
      window.questions.push(this)
    }
  }
  TextQuestionClass.initClass()
  return TextQuestionClass
})()

// @ts-ignore
window.Taker.TextQuestion = TextQuestion

export default TextQuestion
