import * as _ from "lodash"
import noUiSlider from 'nouislider'
### This release has a change that breaks this directive.  Investigate these issues if upgrading
14.5.0 (*2020-05-20*)
- Added: Support for `margin`, `padding` and `limit` on non-linear sliders (#911, #1030, #1031, #1071);
###
# import 'nouislider/src/nouislider.less'
import 'nouislider/distribute/nouislider.css'

multiSampleSlider = (
  $filter
) ->
  "ngInject"

  restrict: "E"
  scope:
    step: "="
    min: "="
    max: "="
    samples: "="
    ngModel: "="
    showAnswerValue: "="
    useSampleColors: "="
    initIndex: "="
    initAnswers: "="
    hideHandle: "="
    orientation: "="
    direction: "="
    onValueChange: "&"

  link: (scope, element, attrs) ->

    values = _.map(scope.samples, (s) -> scope.initAnswers?[s.id]?[0]?.score || 0) || []

    SAMPLE_HEIGHT = 27
    valid_offsets = []
    for i in [0...scope.samples.length]
      valid_offsets[i] = (i+1)*SAMPLE_HEIGHT

    formatter =
      to: (value, index) ->
        return "" if typeof(value) != "number"
        tooltip = $filter('sampleDisplayLabel')(scope.samples[index]?.id)
        tooltip += " - #{Math.round( value * 10 ) / 10}" if scope.showAnswerValue

        color = if scope.useSampleColors then "color:#{scope.samples[index]?.line_color};" else ""
        width = ($($(slider).find("##{scope.samples[index]?.id}"))?.width() / $(slider)?.width()) * scope.max
        width += parseFloat(scope.step) || 1
        offset = SAMPLE_HEIGHT
        if scope.orientation == "horizontal"
          hit_offsets = []
          _.each(values, (v, i) ->
            hit = i != index && Math.abs(value - v) < width
            if hit
              hit_offset = Math.abs(parseInt($($(slider).find("##{scope.samples[i].id}")?[0]?.parentElement).css("top") || SAMPLE_HEIGHT))
              hit_offsets.push(hit_offset)
              offset = _.min(_.difference(valid_offsets, hit_offsets))
          )
          tooltip_element = slider?.querySelectorAll('.noUi-tooltip')[index]
          tooltip_element?.style?.top = "-#{offset}px"
          tooltip_element?.style?.height = "35px"
          tooltip_element?.style?.marginTop = "-8px"


        else
          offset = "10" #todo: implement vertical
        "<span value='#{value}' id='#{scope.samples[index]?.id}' class='multi-sample-line-scale-tooltip' style='pointer-events: none; #{color}' > #{tooltip} </span>"
      from: (value) ->
        if $.isNumeric(value) then value else $($(value)[0]).attr("value")

    slider = $(element)[0]

    noUiSlider.create slider,
      start: values
      step: parseFloat(scope.step || 1)
      range:
        min: [parseFloat(scope.min)]
        max: [parseFloat(scope.max)]
      orientation: scope.orientation || 'horizontal'
      direction: scope.direction || 'rtl'
      margin: -parseFloat(Math.abs(scope.min) + Math.abs(scope.max)) # the maximum value the pairs of sliders can be apart. Negative because we're changing the default behavior of the library, which is built for ranges
      tooltips: true
      format: formatter
    origins = $(slider).find(".noUi-origin")

    if scope.useSampleColors
      ticks = $(slider).find(".noUi-handle")
      _.each(ticks, (tick, index) ->
        $(tick).css('background-color', scope.samples[index]?.line_color)
      )

    focusSample = (index) ->
      if index != null && 0 <= index < scope.samples.length
        _.each(origins, (el, i) ->
          if index == i
            el.removeAttribute('disabled')
          else
            el.setAttribute('disabled', true)
        )
        scope.initIndex = index
      else
        clearFocus()

    clearFocus = () ->
      scope.initIndex = null
      _.each origins, (el) ->
        el.removeAttribute('disabled')

    slider.noUiSlider.on 'slide', (tooltips, handle, scores)->
      values = scores
      $(origins[handle]).css("visibility", "visible")
      parsedValue = Math.round( parseFloat(values[handle]) * 10 ) / 10
      $(slider).removeClass "no-handle"
      phase = scope.$root.$$phase
      if(phase == '$apply' || phase == '$digest')
        scope.ngModel = {score:parsedValue, sample_id: scope.samples[handle].id}
        focusSample(++num_answers)
      else
        scope.$apply ->
          scope.ngModel = {score:parsedValue, sample_id: scope.samples[handle].id}
          focusSample(++num_answers)

      scope.onValueChange()

    # slider.noUiSlider.on 'change', ()->

    origins.css("visibility", "hidden")
    num_answers = 0
    _.each( scope.samples, (s, i) ->
      sample_score = scope.initAnswers?[s.id]?[0]?.score
      if(sample_score || sample_score == 0)
        $(origins[i]).css("visibility", "visible")
        num_answers = i+1
        return true
      else
        return false
    )
    focusSample(num_answers) if (num_answers < scope.samples.length)

    scope.$watch("end", (newVal, oldVal) ->
      if newVal && !_.isNaN(parseFloat(newVal))
        slider.noUiSlider.options.range =
            min: [parseFloat(scope.min)]
            max: [parseFloat(scope.max)]
    )

    scope.$watch("step", (newVal, oldVal) ->
      if newVal && !_.isNaN(parseFloat(newVal))
        slider.noUiSlider.options.step = parseFloat(scope.step or 1)
    )

    scope.$watch("orientation", (newVal, oldVal) ->
      if newVal
        slider.noUiSlider.options.orientation = newVal
    )

    scope.$watch("direction", (newVal, oldVal) ->
      if newVal
        slider.noUiSlider.options.direction = newVal
    )

    ignoreSimulatedIOsClick = (type) ->
      $(element)[0].addEventListener(type, (e) ->
        if(e.type == "mousedown" && !e.buttons && !e.touches)
          e.preventDefault()
          e.stopPropagation()
          return false
      , true)
    ignoreSimulatedIOsClick('mousedown')
    ignoreSimulatedIOsClick('mouseup')
    ignoreSimulatedIOsClick('click')
    ignoreSimulatedIOsClick('mousemove')

    return

export default multiSampleSlider
