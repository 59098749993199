class SingleResponseGridQuestion extends Taker.GridQuestion
  @icon: "fa fa-th"
  @display_name: "Single Response Grid"
  @question_type: "single_response_grid"

  validate: ->
    @error_messages = []
    if @validations && @is_visible
      _.each @validations, (rule) =>
        if rule.type is "required"
          if !(_.every @groups, (group) -> group.selected_answers && group.selected_answers.length > 0)
            @error_messages.push {type: "grid_required"}
    @error_messages.length is 0

  recordAnswer: (group, answer) ->
    selected_answer = @createSelectedAnswer(answer)
    # if this question requires unique responses then delete that response from any groups that are using it
    if (_.any @validations, (validation) -> return validation.type == "unique responses")
     _.each @groups, (sister_group) ->
       if (_.find sister_group.selected_answers, (answer) -> selected_answer.id == answer.id)
         sister_group.selected_answers = [] #clear out other groups that have picked this answer

    group.selected_answers = [selected_answer]
    @validate() if @error_messages and @error_messages.length > 0
    @publishAnswerSelectedEvent()

  # isAnswerSelected: (group, answer) ->
  #   if _.find(group.selected_answers, {id: answer.id}) then true else false

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.SingleResponseGridQuestion = SingleResponseGridQuestion
