import * as angular from "angular"


ui_invite_myredjade = angular.module('redjade.survey_taker.ui.invite_myredjade', [

])
  .controller('inviteMyredjadeCtrl', require("./inviteMyredjade.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/invite_myredjade/invite_myredjade.html', require("./invite_myredjade.html"))
  )

export default ui_invite_myredjade
