import * as _ from "lodash"

sampleDisplayLabel = (
  surveyHelper
  languageHelper
  $sce
) ->
  "ngInject"

  return (sample_id, samples) ->
    samples = surveyHelper.samples unless _.any samples
    label = ''
    sample = _.find(samples, {id: parseInt(sample_id)})
    unless sample
      return label
    if surveyHelper.questionnaire.sample_entry_display == 'sample_name'
      label = sample.name
    else
      label = sample.code || sample.identifier || '' if sample

    include_ordinal = surveyHelper.questionnaire.questionnaire_type == 'multi_non_sample'
    if include_ordinal
      seq_translation = languageHelper[surveyHelper.locale]['ordinals[' + sample.sequence_position + ']']
      label = "#{seq_translation}: #{label}" if seq_translation

    label

export default sampleDisplayLabel
