class NappingQuestion extends Taker.BaseQuestion
  @icon: "fa fa-bed"
  @display_name: "Napping"
  @question_type: "napping"

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

  validate: ->
    @error_messages = []
    if @is_visible
      if @validations
        _.each @validations, (rule) =>
          if rule.type is "required"
            if @selected_answers.length < 1
              @error_messages.push {type: "required"}

      _.each @selected_answers, (ans) =>
        if ans.hasOwnProperty('text') && ans.text.length < 1
          @error_messages.push {type: "required"}

      empty_name_found = false

      region_ids_without_names = []

      _.each @regions, (region) ->
        str = region.name
        if (!region.name || 0 == region.name.length)
          region_ids_without_names.push(region.id)
          empty_name_found = true

      if empty_name_found
        @error_messages.push {type: "required", value: region_ids_without_names}

    @error_messages.length is 0

  recordAnswer: (samples) ->
    @selected_answers = samples
    @publishAnswerSelectedEvent()

window.Taker.NappingQuestion = NappingQuestion
