class SensoryWheelQuestion extends Taker.RangeSliderQuestion
  @icon: "fa fa-caret-square-o-down"
  @display_name: "Sensory Wheel"
  @question_type: "sensory_wheel"
  @readyToMountSvelte = false

  validate: (_sample_length, locale, sectionCid = null) ->
    return if !@is_visible

    @error_messages = []
    @modal_errors = []
    sections = @config.sensory_wheel.sections
    followupType = @config.sensory_wheel.followup.type.value

    answers = @selected_answers
    if sectionCid
      answers = @selected_answers.filter((a) -> a.questionAnswerCid == sectionCid)
      sections = sections.filter((a) -> a.cid == sectionCid)
    errors = []
    validations = @validations.slice()

    if followupType == 'numeric'
      # remove any validations set by the validation tab in the builder
      validations = validations.filter((v) -> v.type != 'range')
      validations.push({type: 'range', min: @config.min || 0, max: @config.max})
      validations.push({type: 'range', min: @dynamic_min_value}) unless [undefined, null].includes(@dynamic_min_value)
      validations.push({type: 'range', max: @dynamic_max_value}) unless [undefined, null].includes(@dynamic_max_value)

    _.each validations, (rule) ->
      if rule.type == "range" && answers.length > 0 && ['range', 'numeric'].includes(followupType)
        invalidMin = answers.filter (sa) -> sa.score < parseInt(rule.min)
        invalidMax = answers.filter (sa) -> sa.score > parseInt(rule.max)
        if ![undefined, null].includes(rule.min) && invalidMin.length > 0
          invalidNames = invalidMin.map((a) -> sections.find((s) -> s.cid == a.questionAnswerCid).text[locale]).join(', ')
          errors.push({ type: "min", value: "#{rule.min}", postfix: " #{invalidNames}" })
        if ![undefined, null].includes(rule.max) && invalidMax.length > 0
          invalidNames = invalidMin.map((a) -> sections.find((s) -> s.cid == a.questionAnswerCid).text[locale]).join(', ')
          errors.push({ type: "max", value: "#{rule.max}", postfix: " #{invalidNames}" })

      if rule.type == "required" && !sectionCid
        if answers.length == 0 || (followupType == 'none' && answers.filter((a) -> a.score == 1).length == 0)
          errors.push {type: "required"}

    selectedAnswers = answers.map((sa) -> sa.questionAnswerCid)
    if !sectionCid && errors.length == 0 && sections.filter((a) -> a.config.treeView.required && !a.config.treeView.disabled && !selectedAnswers.includes(a.cid)).length > 0
      errors.push {type: "required"}

    if sectionCid
      @modal_errors = errors
    else
      @error_messages = errors

    errors.length == 0

  blur: (locale) ->
    @validate(null, locale, @config.sensory_wheel.followup.activeCid)
    return if @modal_errors.length > 0

    @autoAnswerParent()

    setTimeout =>
      @config.sensory_wheel.followup.visible = false
      @config.sensory_wheel.followup.activeCid = null
      # refresh the wheel component
      @refreshSections = !@refreshSections
      # prevent modal from being opened/close when it shouldn't on refresh
      @publishAnswerSelectedEvent()
    , 10

  autoAnswerParent: () ->
    followup = @config.sensory_wheel.followup
    return unless @config.sensory_wheel.update_parent_value_by_child && followup != 'none'

    activeCid = followup.activeCid
    questionAnswer = @config.sensory_wheel.sections.find((a) -> a.cid == activeCid)
    return unless questionAnswer

    # if we answered an outer section and the parent is answerable we want to update its answer as well so the upper limit is set for the next section
    if questionAnswer.config.treeView.parent_cid
      parent = @config.sensory_wheel.sections.find((a) -> a.cid == questionAnswer.config.treeView.parent_cid)
      parentSelectedAnswer = @selected_answers.find((a) -> a.questionAnswerCid == questionAnswer.config.treeView.parent_cid)

      answers = @answers
      selectedAnswer = @selected_answers.filter((a) -> a.questionAnswerCid == questionAnswer.cid)
      if selectedAnswer?.length > 0
        selectedAnswer = selectedAnswer.reduce (prev,  curr) ->
          prevAnswer = answers.find((a) -> a.id == prev.id)
          currAnswer = answers.find((a) -> a.id == curr.id)
          if prevAnswer.value > currAnswer.value then prev else curr

      if selectedAnswer && !parent.config.treeView.disabled && !parentSelectedAnswer
        followup.activeCid = parent.cid
        if ['checkbox', 'radio'].includes(followup.type.value)
          @recordAnswer(selectedAnswer)
        else
          @recordAnswer(selectedAnswer.score)
        followup.activeCid = null

  autoScrollToFollowup: (section) ->
    elementId = "#{@id}-#{section.id}"
    setTimeout(() ->
      e = document.getElementById(elementId)
      return unless e

      if e.scrollIntoViewIfNeeded
        e.scrollIntoViewIfNeeded({ behavior: 'smooth' })
      else if e.scrollIntoView
        e.scrollIntoView({ behavior: 'smooth' })
    , 100)

  sectionClicked: (section) ->
    return if @config.sensory_wheel.followup.visible == true

    @setLimitsByChildren(section)
    @setLimitsByParent(section)
    followup = @config.sensory_wheel.followup
    builderAnswer = @config.sensory_wheel.sections.find((a) -> a.cid == section.id)
    selectedAnswer = @selected_answers.find((a) -> a.questionAnswerCid == builderAnswer.cid)
    followup.activeCid = section.id
    if followup.type.value != 'none'
      @autoScrollToFollowup(section)
      followup.visible = true
      # prevent modal from being opened/close when it shouldn't on refresh
      @publishAnswerSelectedEvent()

    if followup.type.value == 'range'
      @current_slider_val = selectedAnswer?.score
    else if ['radio', 'numeric'].includes(followup.type.value)
      @current_answer = @selected_answers.find((a) -> a.questionAnswerCid == builderAnswer.cid)
    else if followup.type.value == 'none'
      @recordAnswer((if selectedAnswer && selectedAnswer.score == 1 then 0 else 1))
      followup.activeCid = null

  setLimitsByChildren: (section) ->
    @dynamic_min_value = null
    return unless @config.sensory_wheel.limit_parent_value_by_child

    followup = @config.sensory_wheel.followup.type.value
    for answer in @answers
      answer.disabled_by_child = false

    return unless ['checkbox', 'radio', 'numeric'].includes(followup)

    minValue = null
    for child in @config.sensory_wheel.sections
      if child.config.treeView.parent_cid != section.id
        continue

      selectedAnswer = null
      child_answers = @selected_answers.filter((a) -> a.questionAnswerCid == child.cid)
      if child_answers?.length > 0
        answers = @answers
        selectedAnswer = child_answers.reduce (prev,  curr) ->
          if followup == 'numeric'
            if prev.score > curr.score then prev else curr
          else if ['checkbox', 'radio'].includes(followup)
            prevAnswer = answers.find((a) -> a.id == prev.id)
            currAnswer = answers.find((a) -> a.id == curr.id)
            if prevAnswer.value > currAnswer.value then prev else curr

      answer = if selectedAnswer then @answers.find((a) -> a.id == selectedAnswer.id) else null

      if ['checkbox', 'radio'].includes(followup) && answer && answer.value > minValue
        minValue = answer.value
      if followup == 'numeric' && selectedAnswer && selectedAnswer.score > minValue
        minValue = selectedAnswer.score

    if typeof minValue == 'number'
      if ['checkbox', 'radio'].includes(followup)
        for answer in @answers
          if answer.value < minValue
            answer.disabled_by_child = true
      if followup == 'numeric'
        @dynamic_min_value = minValue

  setLimitsByParent: (section) ->
    @dynamic_max_value = null
    return unless @config.sensory_wheel.limit_child_value_by_parent

    followup = @config.sensory_wheel.followup.type.value
    for answer in @answers
      answer.disabled_by_parent = false

    return unless ['checkbox', 'radio', 'numeric'].includes(followup) && section.parentId

    maxValue = null
    parent = @config.sensory_wheel.sections.find((a) -> a.cid == section.parentId)

    selectedAnswer = null
    if @selected_answers?.length > 0
      answers = @answers
      selectedAnswer = @selected_answers.find((a) -> a.questionAnswerCid == parent.cid)

    answer = if selectedAnswer then @answers.find((a) -> a.id == selectedAnswer.id) else null

    if ['checkbox', 'radio'].includes(followup) && answer != null
      maxValue = answer.value
    if followup == 'numeric' && selectedAnswer
      maxValue = selectedAnswer.score

    if typeof maxValue == 'number'
      if ['checkbox', 'radio'].includes(followup)
        for answer in @answers
          if answer.value > maxValue
            answer.disabled_by_parent = true
      if followup == 'numeric'
        @dynamic_max_value = maxValue

  shouldOverrideAnswer: (value) ->
    return if [null, undefined].includes(value)

    min = @answerMin()
    max = @answerMax()
    if min && value < min.score
      return min.score
    if max && value > max.score
      return max.score
    null

  computePreviousSampleOffset: (_a, _b) ->
    return ''

  clearSectionValue: (section) ->
    @selected_answers = @selected_answers.filter((sa) -> sa.questionAnswerCid != section.cid)
    @current_answer = null
    @current_slider_val = null
    @publishAnswerSelectedEvent()

  recordAnswer: (answer) ->
    return if answer?.disabled_by_child || answer?.disabled_by_parent

    followup = @config.sensory_wheel.followup
    return if [undefined, null].includes(answer) || (followup.type.value == 'numeric' && @config.min > answer > @config.max)

    if followup.type.value == 'range' && @shouldOverrideAnswer(answer)
      answer = @shouldOverrideAnswer(answer)

    # we discard questionAnswerCid in the rails serializer when we split into individual questions
    activeCid = followup.activeCid
    if ['radio', 'checkbox'].includes(followup.type.value)
      if followup.type.value == 'checkbox'
        existingAnswer = _.find(@selected_answers, {id: answer?.id, questionAnswerCid: activeCid})
      else
        existingAnswer = _.find(@selected_answers, {questionAnswerCid: activeCid})

      if existingAnswer && (followup.type.value == 'checkbox' || existingAnswer.id != answer.id)
        @selected_answers = _.without(@selected_answers, existingAnswer)

      if !existingAnswer || existingAnswer.id != answer.id
        @current_answer =
          id: answer.id
          answered_at: Date.now()
          questionAnswerCid: activeCid
        @selected_answers.push @current_answer
    else
      existingAnswer = _.find(@selected_answers, {questionAnswerCid: activeCid})
      if existingAnswer
        @selected_answers = _.without(@selected_answers, existingAnswer)
      @current_answer =
        score: answer
        answered_at: Date.now()
        questionAnswerCid: activeCid
      @selected_answers.push @current_answer

    @validate() if @error_messages and @error_messages.length > 0
    @publishAnswerSelectedEvent()

  isAnswerSelected: (answer) ->
    !!_.find(@selected_answers, {id: answer.id, questionAnswerCid: @config.sensory_wheel.followup.activeCid})

  # answer no higher than parent lowest answer
  answerMax: ->
    return unless @config.sensory_wheel.limit_child_value_by_parent

    activeCid = @config.sensory_wheel.followup.activeCid
    activeBuilderAnswer = @config.sensory_wheel.sections.find((a) -> a.cid == activeCid)
    parentBuilderAnswer = activeBuilderAnswer
    count = 0
    while true
      count++
      parentBuilderAnswer = @config.sensory_wheel.sections.find((a) -> a.cid == parentBuilderAnswer.config.treeView.parent_cid)
      parentSelectedAnswer = @selected_answers.find((a) -> a.questionAnswerCid == parentBuilderAnswer.cid) if parentBuilderAnswer
      break if !parentBuilderAnswer || parentSelectedAnswer || count > 100

    # if a section has a parent that has been answered then that sets a max
    return parentSelectedAnswer

  # answer no lower than child highest answer
  answerMin: ->
    return unless @config.sensory_wheel.limit_parent_value_by_child

    min = null
    answers = []
    @getChildAnswers(@config.sensory_wheel.followup.activeCid, answers)
    # if a section has a child that has been answered then that sets the min so overall doesnt limit lower than existing
    if answers.length > 0
      min = answers.slice().sort((a,b) -> if a.score < b.score then -1 else 1)[0]
    return min

  getChildAnswers: (sectionCid, answers) ->
    childSections = @config.sensory_wheel.sections.filter((a) -> a.config.treeView.parent_cid == sectionCid)
    for childSection in childSections
      selectedAnswer = @selected_answers.find((a) -> a.questionAnswerCid == childSection.cid)
      if selectedAnswer
        answers.push(selectedAnswer)
      @getChildAnswers(childSection.cid, answers)

  getRestrictingSectionAnswers: (locale) ->
    min = @config.min
    max = @config.max
    diff = max - min
    a = @answerMin() || @answerMax()
    return [] unless a

    [{
      id: a.cid
      position: ((a.score - min) / diff) * 100
      score: a.score
      line_color: '#828282'
      serving_id: a.cid
    }]

  getAncestors: (section, locale) ->
    region = @config.sensory_wheel.sections.find((a) -> a.cid == section.config.treeView.parent_cid)
    text = if region then "#{region.text[locale]}:" else ""
    if !region
      return ''

    modality = @config.sensory_wheel.sections.find((a) -> a.cid == region.config.treeView.parent_cid)
    if modality && modality.text[locale] && modality.text[locale].trim() != '' && modality.text[locale] != region.text[locale]
      text = "#{modality.text[locale]} > #{text}"
    text

  followupStyling: (orientation) ->
    style = {
      'position': 'absolute'
      'top': '50%'
      'left': '50%'
      'transform': 'translate(-50%, -50%)'
      'background-color': '#f4f4f4'
      'text-align': 'center'
      'padding': '0px 10px 50px 10px'
      'box-shadow': '0px 0px 10px 0px rgba(0, 0, 0, 0.75)'
      'min-width': '450px'
      'z-index': '13'
    }

    if orientation == 'horizontal'
      style['width'] = '100%'
    else
      style['max-width'] = '450px'

    return style

  constructor: (data) ->
    init_data =
      config:
        sensory_wheel:
          followup:
            visible: false
            activeCid: null

    _.merge(init_data, data)
    super init_data

    @config.question_text_template_hidden = true

window.Taker.SensoryWheelQuestion = SensoryWheelQuestion
