class TemporalCata extends Taker.BaseQuestion
  @icon: "fa fa-trophy"
  @display_name: "Temporal Cata"
  @question_type: "temporal_cata"

  isSelected: (answer) ->
    if @selected_answers.length == 0
      return false

    latest_answers = @selected_answers.reduce (agg, a) ->
      agg[a.id] ||= a
      if agg[a.id].time < a.time
        agg[a.id] = a
      agg
    , {}

    if answer.no_sensation
      return ((value for own key, value of latest_answers).filter (a) -> a.is_active).length == 0
    else
      return if latest_answers[answer.id] then latest_answers[answer.id].is_active else false

  # this gets called by the timed.controller module first
  recordAnswer: (answer_id, time, question) ->
    answered_at = Date.now()
    answer = @findAnswerByID(answer_id)
    if question && answer && answer.no_sensation
      for a in question.answers
        continue if a && a.no_sensation

        @selected_answers.push({id: a.id, time: time, answered_at: answered_at, is_active: false})
    else
      @selected_answers.push({id: answer_id, time: time, answered_at: answered_at, is_active: !this.isSelected(answer)})

    @validate() if @error_messages and @error_messages.length > 0

    @publishAnswerSelectedEvent()

  constructor: (data) ->
    init_data =
      selected_answers: []

    _.merge(init_data, data)
    super init_data

window.Taker.TemporalCata = TemporalCata
