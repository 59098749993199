import * as angular from "angular"

ui_sample_entry = angular.module('redjade.survey_taker.ui.sample_entry', [
])
  .controller('sampleEntryCtrl', require("./sampleEntry.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/sample_entry/sample_entry.html', require("./sample_entry.html"))
  )

export default ui_sample_entry
