
class FreeSortQuestion extends Taker.BaseQuestion
  @icon: "fa fa-check-square-o"
  @display_name: "Free Sort"
  @question_type: "free_sort"

  validate: ->
    @error_messages = []
    if @selected_answers.filter((answer) -> answer.sample_id?).length != SurveyHelper.samples.length
      @error_messages.push {type: "required"}
    if @selected_answers.length > SurveyHelper.samples.length
      @error_messages.push {type: "empty_group"}
    if @selected_answers.filter((answer) -> answer.text == "").length > 0
      @error_messages.push {type: "empty_group_names"}

    grouped_answers = @selected_answers.reduce((groups, answer) ->
      key = answer.group_position
      groups[key] = answer.text if !groups[key]
      groups
    , {})
    values = Object.values(grouped_answers)
    set = new Set()

    duplicate_group_names = values.some((value) ->
      normalizedValue = value.toLowerCase().replace(/\s/g, '')
      if set.has(normalizedValue)
        true
      else
        set.add(normalizedValue)
        false
    )

    if @config.force_group_names && duplicate_group_names
      @error_messages.push {type: "duplicate_group_names"}
      RjEvent.emit "taker-validation-error", 'duplicate_group_names'

    @error_messages.length is 0

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.FreeSortQuestion = FreeSortQuestion