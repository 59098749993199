class PageText extends Taker.BasePageElement
	@icon: "fa fa-paragraph"
	@display_name: "PageText"
	@question_type: "page_text"
	@has_font_size: false

	constructor: (data) ->
		init_data = {}
		_.merge(init_data, data)
		super init_data

window.Taker.PageText = PageText
