class DuoTrio extends Taker.DiscrimQuestion
  @icon: "fa fa-dot-circle-o"
  @display_name: "Duo-Trio"
  @question_type: "discrim:duo_trio"
  @file_name: "duo_trio"

  determineCorrectness: (surveyHelper) ->
    grouped_samples = _.groupBy(surveyHelper.samples, 'id')
    sorted = _.sortBy(grouped_samples, 'length')
    correct_sample = sorted[sorted.length - 1][0]

    @is_correct = @selected_answers[0].sample_id == correct_sample.id

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.DuoTrio = DuoTrio
