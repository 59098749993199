class MaxDiffQuestion extends Taker.ChoiceModelQuestion
  @icon: "fa fa-dot-circle-o"
  @display_name: "MaxDiff"
  @question_type: "max_diff"
  @must_click_next: false
  @wipe_if_answers_change: false

  validate: ->
    @error_messages = []
    if @validations && @is_visible
      _.each @validations, (rule) =>
        if rule.type is "required"
          for set_index in [0..@config.max_diff.sets_per_participant - 1]
            unless @setIsAnswered(set_index)
              @error_messages.push {type: "required"}
              return false

    @error_messages.length is 0

  getProfile: (profile_index, is_preview, builderHelper = null) ->
    profile = { id: null, attributes: [] }

    @config.preview ||= {}
    @config.preview.generated ||= {}

    if is_preview && @settingsChanged()
      @question_profiles = []

    # called by builder when previewing questionnaire OR when previewing event (no builder)
    if (is_preview && (!@question_profiles || !@question_profiles[profile_index] || !@config.preview.generated[profile_index] ||
       @config.preview.per_set != @config.max_diff.attributes_per_set || @config.preview.total != @config.max_diff.sets_per_participant))
      builderHelper?.ignore_pending_changes = true
      for index in [0..@config.max_diff.sets_per_participant]
        @generatePreviewProfiles(index)
      builderHelper?.ignore_pending_changes = false

    profile.id = @question_profiles[profile_index].id
    for id in _.uniq(@question_profiles[profile_index].question_attribute_ids)
      profile.attributes.push(@question_attributes.find((qa) -> qa.id == id))

    return profile

  # event preview or survey previewer
  settingsChanged: () ->
    @preview_profiles_settings ||= {}
    return @preview_profiles_settings.attributes_per_set != @config.max_diff.attributes_per_set ||
            @preview_profiles_settings.sets_per_participant != @config.max_diff.sets_per_participant ||
            JSON.stringify(@preview_profiles_settings.attribute_names) != JSON.stringify(@question_attributes.map((a) -> a.text)) ||
            JSON.stringify(@preview_profiles_settings.attribute_ids) != JSON.stringify(@question_attributes.map((a) -> a.id))

  generatePreviewProfiles: (profile_index) ->
    # cache so we can react to settings changing in preview
    @preview_profiles_settings =
      attributes_per_set: @config.max_diff.attributes_per_set
      sets_per_participant: @config.max_diff.sets_per_participant
      attribute_names: @question_attributes.map((a) -> a.text)
      attribute_ids: @question_attributes.map((a) -> a.id)

    @config.preview.generated[profile_index] = true
    @config.preview.per_set = @config.max_diff.attributes_per_set
    @config.preview.total = @config.max_diff.sets_per_participant

    @question_attributes.forEach (a, i) ->
      unless a?.id
        a.id = i

    @question_profiles ||= []
    @question_profiles[profile_index] = { id: profile_index, question_attribute_ids: [] }

    for [1..@config.max_diff.attributes_per_set]
      count = 0
      while true
        rndIndex = Math.floor(Math.random() * (@question_attributes.length - 1))
        id = @question_attributes[rndIndex].id
        if @question_attributes.length <= @config.max_diff.attributes_per_set || !@question_profiles[profile_index].question_attribute_ids.includes(id)
          @question_profiles[profile_index].question_attribute_ids.push(id)
          break
        count++
        if count > 1000
          #console.log "MaxDiffQuestion.generatePreviewProfiles() taker infinite loop"
          break


  previousSet: ->
    @wipe_if_answers_change = true # if any answer changed then reset on clicking back then the next answer click
    @config.max_diff.current_set_number-- unless @config.max_diff.current_set_number <= 0

  showNext: (is_preview) ->
    attr_ids = @getProfile(@config.max_diff.current_set_number).attributes.map((a) -> a.id)
    @setIsAnswered() && !@isLastSet()

  nextSet: (is_preview, force = false) ->
    @must_click_next = false if force
    return unless @setIsAnswered() || force

    @config.max_diff.current_set_number++ unless @isLastSet()
    @wipe_if_answers_change = @showNext(is_preview)

  isLastSet: ->
    @config.max_diff.current_set_number >= @config.max_diff.sets_per_participant - 1

  setIsAnswered: (profile_index = @config.max_diff.current_set_number) ->
    profile_attr_ids = @getProfile(profile_index).attributes.map((a) -> a.id)

    most_answer = @groups[0].selected_answers.find((a) -> profile_attr_ids.includes(a.selected_attribute_id) && a.trial_num == profile_index + 1)
    least_answer = @groups[1].selected_answers.find((a) -> profile_attr_ids.includes(a.selected_attribute_id) && a.trial_num == profile_index + 1)

    least_answer && most_answer

  getAnswer: (profile_index, attribute_id, least_most) ->
    @groups[least_most].selected_answers.find((a) -> a.selected_attribute_id == attribute_id && a.trial_num == profile_index + 1)

  recordAnswer: (is_preview, least_most, profile_index, profile_id, attribute_id) ->
    profile_attr_ids = @getProfile(profile_index).attributes.map((a) -> a.id)

    for group_index in [0,1]
      wipe_if_answers_change = @wipe_if_answers_change
      @groups[group_index].selected_answers = @groups[group_index].selected_answers.filter (a) ->
        if profile_attr_ids.includes(a.selected_attribute_id) && a.trial_num == profile_index + 1 &&
            (wipe_if_answers_change || least_most == group_index || a.selected_attribute_id == attribute_id)
          false
        else
          true

    answer =
      trial_num: profile_index + 1
      trial_profiles_ids: [profile_id]
      selected_attribute_id: attribute_id
      answered_at: Date.now()

    @groups[least_most].selected_answers.push(answer)

    @wipe_if_answers_change = false
    if @setIsAnswered(answer.trial_num - 1) && !@must_click_next
      @nextSet(is_preview)

    @publishAnswerSelectedEvent()

  constructor: (data) ->
    init_data = {
      selected_answers: []
      config:
        max_diff:
          current_set_number: 0
    }
    _.merge(init_data, data)
    super init_data

window.Taker.MaxDiffQuestion = MaxDiffQuestion
