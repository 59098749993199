class DiscrimQuestion extends Taker.BaseQuestion
  @is_discrim = true
  @is_correct = null
  @has_drag_drop = false


  recordAnswer: (sample) ->
    @selected_answers = [{serving_sample_id: sample.serving_sample_id, sample_id: sample.id, answered_at: Date.now()}]
    @validate() if @error_messages and @error_messages.length > 0
    @publishAnswerSelectedEvent()

  determineCorrectness: (surveyHelper) ->
    surveyHelper.duoTrioSamples()

  constructor: (data) ->
    discrim_defaults = {}
    _.merge(discrim_defaults, data)
    super discrim_defaults

window.Taker.DiscrimQuestion = DiscrimQuestion
