class GridQuestion extends Taker.BaseQuestion
	@is_grid: true


	constructor: (data) ->
		grid_defaults = {}
		_.merge(grid_defaults, data)
		super grid_defaults

window.Taker.GridQuestion = GridQuestion
