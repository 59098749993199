class Media extends Taker.BasePageElement
	@icon: "fa fa-image"
	@display_name: "Media"
	@question_type: "media"
	@has_dimensions: true

	isVideo: () ->
		suffix = "mp4"
		if !@config.media_source_url
			return false
		else
			return @config.media_source_url.toLowerCase().indexOf(".mp4") > -1


	constructor: (data) ->
		init_data = {}
		_.merge(init_data, data)
		super init_data

window.Taker.Media = Media
