import urls from "~angular/src/rj_shared/utils/urls"
import tokenManager from "~angular/src/rj_shared/utils/token_manager"

consentPageCtrl = (
  $scope
  $state
  surveyDataAdapter
  $sce
  $q
  $timeout
  $window
  languageHelper
  appInfo
  $injector
  $stateParams
  localStorageService
) ->
  "ngInject"

  $scope.model = {
    accepted_consent: false,
    reached_end: false,
    clicked_decline: false
  }

  if ($state.current.data)
    $scope.from_previewer = $state.current.data.from_previewer
    $scope.previewUrl = "/previewer/?project_id=#{localStorageService.get("previewer_project_id")}&sensory_test_id=" +
      "#{localStorageService.get("previewer_sensory_test_id")}&event_id=#{localStorageService.get("previewer_event_id")}"

  $scope.languageHelper = languageHelper
  $scope.appinfo = appInfo

  setScreenDefaults = ->
    await languageHelper.load($scope.locale)
    screens = $scope.meta.screens
    locale = $scope.locale
    if !screens[locale]
      screens[locale] = {}
    if !screens[locale].CONSENT_HEADER_HTML
      screens[locale].CONSENT_HEADER_HTML = languageHelper[locale].CONSENT_HEADER_HTML
    if !screens[locale].CONSENT_FORM_HTML
      screens[locale].CONSENT_FORM_HTML = languageHelper[locale].CONSENT_FORM_HTML

  $scope.renderHtml = (html_code) ->
    $sce.trustAsHtml(html_code)

  $scope.respondToTerms = (response) ->
    accepted_consent = response == true
    if accepted_consent
      surveyDataAdapter.accept_terms(accepted_consent).then (result) ->
        # hack so that if multi event routes.config.coffee doesn't ignore routing to next consent page
        $window.location.reload()
      , (res) ->
        $scope.errors = res.data.messages
    else
      tokenManager.remove('online_redirect')
      $scope.model.clicked_decline = true
      surveyDataAdapter.accept_terms(accepted_consent).then (result) ->
        $timeout(->
          $state.go("participant_entry")
        , 120000)
      , (res) ->
        $scope.errors = res.data.messages

  $scope.returnToSignIn = ->
    $window.location.reload()
    # # this is only if we want it to always sign them out, otherwise we can just refresh the page
    # surveyDataAdapter.sessionSignOut().then (meta) ->
    #   $scope.locale = meta.locale
    #   $scope.meta = meta
    #   tokenManager.remove('survey_participant')
    #   $state.go("participant_entry")
    # , (error) ->
    #   tokenManager.remove('survey_participant')
    #   $state.go("participant_entry")

  fetchData =  ->
    # for the case of accepting consent for multiple events on kiosk we need to be able to feed the specific participant
    # because otherwise it will default to SSE incomplete and we'll still see the first participant
    surveyDataAdapter.fetchCurrentParticipant(lite: true).then (current_participant) ->
      $scope.locale = current_participant.locale
      surveyDataAdapter.fetchMeta({ participant_id: current_participant.id }, true).then (meta) ->
        $scope.meta = meta
        setScreenDefaults()
        $timeout(initializeConsentScroll,500) #make them wait at least a half second before accepting consent

  initializeConsentScroll = ->
    el = $('#consent_text')
    if el.prop('scrollHeight') <= el.prop('clientHeight')
      $scope.model.reached_end = true
    else
      el.bind 'scroll', ->
        if $(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight - 5
          $scope.model.reached_end = true
          $scope.$apply()
    return


  fetchData()
  return



export default consentPageCtrl

