import urls from "~angular/src/rj_shared/utils/urls"
import tokenManager from "~angular/src/rj_shared/utils/token_manager"

completedSurveyCtrl = (
  $scope
  Restangular
  $sce
  $timeout
  $location
  languageHelper
  surveyDataAdapter
  appInfo
) ->
  "ngInject"


  $scope.loading = true

  if !tokenManager.get('survey_participant')
    window.location = surveyDataAdapter.complete_path

  surveyDataAdapter.signOutParticipant().then (meta) ->
    $scope.locale = meta.locale
    $scope.meta = meta
    delay = meta.delay_ms
    redirect = ->
      unless meta.incomplete
        tokenManager.remove('survey_participant')
      window.location = surveyDataAdapter.complete_path
    if delay == 0
      redirect()
    else
      $scope.loading = false
      $timeout (() ->
        redirect()
      ), delay
    setScreenDefaults()
    tokenManager.remove('survey_participant')

  $scope.renderHtml = (html_code) ->
    $sce.trustAsHtml(html_code)

  setScreenDefaults = ->
    await languageHelper.load($scope.locale)
    if !$scope.meta || $scope.meta == "null"
      $scope.meta = {}
      $scope.meta['screens'] = {}
      $scope.meta['screens'][$scope.locale] = {}
    screens = $scope.meta.screens
    locale = $scope.locale
    if !screens[locale]
      screens[locale] = {}
    if !screens[locale].PARTICIPANT_END_HTML
      screens[locale].PARTICIPANT_END_HTML = languageHelper[locale].PARTICIPANT_END_HTML



export default completedSurveyCtrl
