import * as _ from "lodash"
import * as angular from "angular"

rangeSliderPreviousSampleLabels = (
  $timeout
) ->
  "ngInject"

  restrict: "E"
  scope:
    previousSamples: '&'
    question: '='
    orientation: '='
  templateUrl: "survey_taker/question_types/range_slider_previous_sample_labels.html"
  link: ($scope, element, attrs) ->
    $scope.previous_samples = $scope.previousSamples()
    $timeout(->
      prev_sample_styles = _.map $scope.previous_samples, (previous_sample) ->
        value_element = angular.element(element[0].querySelector("#psv-#{previous_sample.serving_id}"))
        offset_style = $scope.question.computePreviousSampleOffset(value_element, previous_sample)
        {
          element: value_element
          offset: offset_style
        }
      _.each prev_sample_styles, (ps) ->
        ps.element.css(ps.offset)
        return

      return
    )
    return

export default rangeSliderPreviousSampleLabels

