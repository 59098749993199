class PreferenceQuestion extends Taker.BaseQuestion
  @icon: "fa fa-star"
  @display_name: "Preference"
  @question_type: "preference"
  @has_orientation: true

  recordAnswer: (sample) ->
    @selected_answers = [{serving_sample_id: sample.serving_sample_id, sample_id: sample.id, answered_at: Date.now()}]
    @publishAnswerSelectedEvent()

  recordNoPreferenceAnswer: () ->
    @selected_answers = [{no_preference: true, answered_at: Date.now()}]
    @publishAnswerSelectedEvent()

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.PreferenceQuestion = PreferenceQuestion
