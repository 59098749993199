class Triangle extends Taker.DiscrimQuestion
  @icon: "fa fa-dot-circle-o"
  @display_name: "Triangle"
  @question_type: "discrim:triangle"
  @file_name: "triangle"

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

  determineCorrectness: (surveyHelper) ->
    samples = surveyHelper.samples

    grouped_samples = _.groupBy(samples, 'id')
    correct_sample = _.find(grouped_samples, (group) -> group.length == 1)[0]

    @is_correct = _.all(@selected_answers, (answer) ->
      answer.sample_id == correct_sample.id
    )

    

window.Taker.Triangle = Triangle
