String::escapeSpecialChars = () ->
  return this.replace(/"/g, '\'')

import controllers from './controllers/index'
import directives from './directives/index'
import filters from './filters/index'
import model from './model/index'
import localStorage from 'angular-local-storage'

import ui_completed_session from './ui/completed_session/index'
import ui_completed_survey from './ui/completed_survey/index'
import ui_consent_page from './ui/consent_page/index'
import ui_invite_myredjade from './ui/invite_myredjade/index'
import ui_palate_cleanse from './ui/palate_cleanse/index'
import ui_questionnaire from './ui/questionnaire/index'
import ui_sample_entry from './ui/sample_entry/index'

# SASS
import '~angular/src/survey_builder/utils/windowHelpers.ts'
import '@neos21/bootstrap3-glyphicons'
import '/node_modules/font-awesome/css/font-awesome.min.css'
import '/node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.min.css'
import './stylesheets/animation.scss'
import './stylesheets/consent.css'
import './stylesheets/napping.scss'
import './stylesheets/nouislider.scss'
import './stylesheets/question-table.sass'
import './stylesheets/questionnaire.scss'
import './stylesheets/review_table.scss'
import './stylesheets/slider.sass'
import './stylesheets/conjoint.sass'
import './stylesheets/temporal_cata.scss'
import './stylesheets/temporal_dominance.scss'
import './stylesheets/text_highlight.scss'
import './stylesheets/trumbowyg.sass'
import './stylesheets/index.scss'


import 'angular-scroll' # duScroll
import 'angular-dragdrop' # ngDragDrop
import "~angular/src/rj_shared/utils/jquery.ui.touch-punch.js"

import * as angular from "angular"

redjade_survey_taker = angular.module('redjade.survey_taker', [
  "LocalStorageModule"
  controllers.name
  directives.name
  filters.name
  model.name
  ui_completed_session.name
  ui_completed_survey.name
  ui_consent_page.name
  ui_invite_myredjade.name
  ui_palate_cleanse.name
  ui_questionnaire.name
  ui_sample_entry.name
  "duScroll"
  "ngDragDrop"
])
  .controller('questionnaireTakerCtrl', require("./controllers/questionnaireTaker.controller").default)
  .controller('questionnaireTimerCtrl', require("./controllers/questionnaireTimer.controller").default)
  .controller('timedCtrl', require("./controllers/timed.controller").default)
  .factory('surveyHelper', require("./services/surveyHelper.factory").default)

export default redjade_survey_taker
