import base_element from './_base_element.service.ts'
import base_question from './_base_question.service.ts'
import discrim from './discrim.service'
import base_page_element from './_base_page_element.service.ts'
import grid from './grid.service'
import radio from './radio.service'
import choice_model from './choice_model.service'
import conjoint from './conjoint.service'
import max_diff from './max_diff.service'
import association from './association.service'
import checkbox from './checkbox.service'
import text from './text.service.ts'
import text_highlight from './text_highlight.service'
import numeric from './numeric.service'
import range from './range.service'
import target from './target.service'
import sensory_wheel from './sensory_wheel.service'
import './range.scss'
import select from './select.service'
import single_response_grid from './single_response_grid.service'
import multiple_response_grid from './multiple_response_grid.service'
import symantec_differential_scale from './symantec_differential_scale.service'
import numeric_grid from './numeric_grid.service'
import ranking from './ranking.service'
import preference from './preference.service'
import time_intensity_scale from './time_intensity_scale.service'
import temporal_cata from './temporal_cata.service'
import temporal_dominance from './temporal_dominance.service'
import page_text from './page_text.service'
import media from './media.service'
import media_upload from './mediaUpload.service'
import questionnaire_timer from './questionnaire_timer.service'
import napping from './napping.service'
import duo_trio from './duo_trio.service'
import triangle from './triangle.service'
import paired from './paired.service'
import anota from './anota.service'
import tetrad from './tetrad.service'
import two_of_five from './two_of_five.service'
import hexad from './hexad.service'
import nafc from './nafc.service'
import review_table from './review_table.service'
import demographic from './demographic.service'
import free_sort from './free_sort.service.coffee'


mod = angular.module('redjade.survey_taker.model.question_types', [])
  .service('BaseElement', base_element)
  .service('BaseQuestion', base_question)
  .service('DiscrimQuestion', discrim)
  .service('BasePageElement', base_page_element)
  .controller('associationCtrl', require("./association.controller").default)
  .controller('pageElementCtrl', require("./pageElement.controller").default)
  .run(($templateCache) ->
    "ngInject"
    $templateCache.put('survey_taker/question_types/radio.html', require('./radio.html'))
    $templateCache.put('survey_taker/question_types/conjoint.html', require('./conjoint.html'))
    $templateCache.put('survey_taker/question_types/max_diff.html', require('./max_diff.html'))
    $templateCache.put('survey_taker/question_types/association.html', require('./association.html'))
    $templateCache.put('survey_taker/question_types/checkbox.html', require('./checkbox.html'))
    $templateCache.put('survey_taker/question_types/demographic.html', require('./demographic.html'))
    $templateCache.put('survey_taker/question_types/text.html', require('./text.html'))
    $templateCache.put('survey_taker/question_types/text_highlight.html', require('./text_highlight.html'))
    $templateCache.put('survey_taker/question_types/numeric.html', require('./numeric.html'))
    $templateCache.put('survey_taker/question_types/range.html', require('./range.html'))
    $templateCache.put('survey_taker/question_types/range_slider_previous_sample_labels.html', require('./range_slider_previous_sample_labels.html'))
    $templateCache.put('survey_taker/question_types/target.html', require('./target.html'))
    $templateCache.put('survey_taker/question_types/select.html', require('./select.html'))
    $templateCache.put('survey_taker/question_types/sensory_wheel.html', require('./sensory_wheel.html'))
    $templateCache.put('survey_taker/question_types/single_response_grid.html', require('./single_response_grid.html'))
    $templateCache.put('survey_taker/question_types/multiple_response_grid.html', require('./multiple_response_grid.html'))
    $templateCache.put('survey_taker/question_types/symantec_differential_scale.html', require('./symantec_differential_scale.html'))
    $templateCache.put('survey_taker/question_types/numeric_grid.html', require('./numeric_grid.html'))
    $templateCache.put('survey_taker/question_types/ranking.html', require('./ranking.html'))
    $templateCache.put('survey_taker/question_types/preference.html', require('./preference.html'))
    $templateCache.put('survey_taker/question_types/time_intensity_scale.html', require('./time_intensity_scale.html'))
    $templateCache.put('survey_taker/question_types/temporal_cata.html', require('./temporal_cata.html'))
    $templateCache.put('survey_taker/question_types/temporal_dominance.html', require('./temporal_dominance.html'))
    $templateCache.put('survey_taker/question_types/page_text.html', require('./page_text.html'))
    $templateCache.put('survey_taker/question_types/media.html', require('./media.html'))
    $templateCache.put('survey_taker/question_types/media_upload.html', require('./media_upload.html'))
    $templateCache.put('survey_taker/question_types/questionnaire_timer.html', require('./questionnaire_timer.html'))
    $templateCache.put('survey_taker/question_types/napping.html', require('./napping.html'))
    $templateCache.put('survey_taker/question_types/duo_trio.html', require('./duo_trio.html'))
    $templateCache.put('survey_taker/question_types/triangle.html', require('./triangle.html'))
    $templateCache.put('survey_taker/question_types/paired.html', require('./paired.html'))
    $templateCache.put('survey_taker/question_types/anota.html', require('./anota.html'))
    $templateCache.put('survey_taker/question_types/tetrad.html', require('./tetrad.html'))
    $templateCache.put('survey_taker/question_types/two_of_five.html', require('./two_of_five.html'))
    $templateCache.put('survey_taker/question_types/hexad.html', require('./hexad.html'))
    $templateCache.put('survey_taker/question_types/nafc.html', require('./nafc.html'))
    $templateCache.put('survey_taker/question_types/review_table.html', require('./review_table.html'))
    $templateCache.put('survey_taker/question_types/zipper.html', require('./zipper.html'))
    $templateCache.put('survey_taker/question_types/free_sort.html', require('./free_sort.html'))
  )

export default mod
