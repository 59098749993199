import tokenManager from "~angular/src/rj_shared/utils/token_manager"

completedSessionCtrl = (
  $scope
  Restangular
  $sce
  $timeout
  $location
  languageHelper
  surveyDataAdapter
  $window
) ->
  "ngInject"


  $scope.loading = true
  surveyDataAdapter.sessionSignOut().then (meta) ->
    $scope.locale = meta.locale
    $scope.meta = meta
    setScreenDefaults()

    delay = meta.delay_ms
    redirect = ->
      tokenManager.remove('survey_participant')
      window.location = surveyDataAdapter.complete_path
    if delay == 0
      redirect()
    else
      $scope.loading = false
      $timeout (() ->
        redirect()
      ), delay
  , (res) ->
    tokenManager.remove('survey_participant')
    window.location = surveyDataAdapter.complete_path

  $scope.renderHtml = (html_code) ->
    $sce.trustAsHtml(html_code)

  setScreenDefaults = ->
    locale = $scope.locale
    await languageHelper.load(locale)
    if !$scope.meta['screens']
      $scope.meta['screens'] = {}
      $scope.meta['screens'][locale] = {}
    screens = $scope.meta.screens

    if !screens[locale]
      screens[locale] = {}
    if !screens[locale].END_OF_MULTIPLE_TESTS_HTML
      screens[locale].END_OF_MULTIPLE_TESTS_HTML = languageHelper[locale].END_OF_MULTIPLE_TESTS_HTML
    if !screens[locale].PARTICIPANT_END_HTML
      screens[locale].PARTICIPANT_END_HTML = languageHelper[locale].PARTICIPANT_END_HTML
    if !screens[locale].STATION_LEAVE_FAIL_HTML
      screens[locale].STATION_LEAVE_FAIL_HTML = languageHelper[locale].STATION_LEAVE_FAIL_HTML
    if !screens[locale].STATION_GO_SIGN_OUT_HTML
      screens[locale].STATION_GO_SIGN_OUT_HTML = languageHelper[locale].STATION_GO_SIGN_OUT_HTML
    if !screens[locale].STATION_GO_TO_FRONT_DESK_HTML
      screens[locale].STATION_GO_TO_FRONT_DESK_HTML = languageHelper[locale].STATION_GO_TO_FRONT_DESK_HTML
    if !screens[locale].STATION_CHECK_IN_SUCCESS_HTML
      screens[locale].STATION_CHECK_IN_SUCCESS_HTML = languageHelper[locale].STATION_CHECK_IN_SUCCESS_HTML
    if !screens[locale].STATION_KIOSK_SIGN_OUT_SUCCESS_HTML
      screens[locale].STATION_KIOSK_SIGN_OUT_SUCCESS_HTML = languageHelper[locale].STATION_KIOSK_SIGN_OUT_SUCCESS_HTML

  return


export default completedSessionCtrl

