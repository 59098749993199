import * as _ from "lodash"
d3 = require("d3")
d3_scale = require("d3-scale")

nappingCanvas = (
  $timeout
  $filter
) ->
  "ngInject"

  return (
    restrict: "AE"
    scope:
      surveyHelper: "="
      question: "="
      languageHelper: "="

    templateUrl: "survey_taker/question_types/napping_canvas.html"

    link: (scope, element, attrs) ->
      drop_zone_width = 185
      height = scope.question.config.width
      # This is to prevent the displayed napping question with canvas width above 885
      # from overflowing the questionnaire page in the builder.
      if scope.surveyHelper.isBuilderPreview && scope.question.config.width > 885
        width = 885 + drop_zone_width
      else
        width = scope.question.config.width + drop_zone_width
      domain = 100
      scope.regions = []
      scope.placing_samples = true
      scope.drawing_region = false
      created_regions_count = 0
      active_region = null
      first_click = true
      first_time = true
      redraw = false
      origin = null
      line = d3.svg.line()
        .x((d) ->
          x_scale d[0]
        )
        .y((d) ->
          y_scale d[1]
        )

      survey_samples = scope.surveyHelper.samples

      x_scale = d3.scale.linear().range([0, width - drop_zone_width]).domain([0, domain])

      x_axis = d3.svg.axis()
        .orient("top")
        .scale(x_scale)

      y_scale = d3.scale.linear().range([0, height]).domain([0, domain])

      inverted_y_scale = d3.scale.linear().range([0, height]).domain([domain, 0])

      y_axis = d3.svg.axis()
        .orient("right")
        .scale(inverted_y_scale)

      # initial placement of samples, re-draw if question already submitted
      _.forEach(survey_samples, (sample, i) ->
        if scope.question.selected_answers && scope.question.selected_answers.length > 0
          answer = _.find(scope.question.selected_answers, (ans) ->
            ans.sample_id == sample.id
          )
          sample.x = answer.x
          sample.y = domain - answer.y
          sample.placed = true
          redraw = true
        else
          sample.x = domain + x_scale.invert(drop_zone_width/2)
          sample.y = (domain / (survey_samples.length + 1)) * (i + 1)
      )

      if redraw
        scope.regions = scope.question.regions
        created_regions_count = scope.question.created_regions_count
        width = width - drop_zone_width

      svg = d3.select('.napping-canvas')
              .attr('width', width)
              .attr('height', height)

      if scope.question.config.show_axis
        svg.append("g")
          .attr("class", "axis")
          .attr("transform", "translate(0," + y_scale(domain) + ")")
          .call(x_axis)

        svg.append("g")
          .attr("class", "axis")
          .attr("transform", "translate(-1,0)")
          .call(y_axis)

      ###########################
      ##### polybrush start #####
      ###########################

      polybrush = (g, param) ->
        bg = g.selectAll(".background").data([0])

        bg.enter().append("rect").attr("class", "background").style("visibility", "hidden")

        ex = scale_extent(x_scale.range())

        bg.attr("x", ex[0]).attr("width", ex[1] - ex[0])

        ey = scale_extent(y_scale.range())

        bg.attr("y", ey[0]).attr("height", ey[1] - ey[0])

        return

      scale_extent = (domain) ->
        start = domain[0]
        stop = domain[domain.length - 1]
        if start < stop
          [start, stop]
        else
          [stop, start]

      polybrush_draw = (g, region_id) ->
        active_region = _.find(scope.regions, (r) -> r.id == region_id)

        fg = g.selectAll(".region-" + region_id).data([active_region.extent])

        g.style("pointer-events", "all").on("click.brush", add_anchor)

        fg.enter().append("path").attr("class", "extent region-" + region_id).attr("id", region_id).style("cursor", "move")

        d3.selectAll(".background").style("cursor", "crosshair")

        return

      add_anchor = ->
        _this = this

        g = d3.select(this)

        w = d3.select(window)

        first_time = true

        if first_click
          active_region.extent.splice 0, active_region.extent.length
          first_click = false
          d3.select('.region-' + active_region.id).on 'mousedown.brush', null
          w.on 'keydown', ->
            if d3.event.keyCode == 27 && scope.drawing_region
              close_path()
            return
          w.on('mousemove.brush', ->
            move_anchor _this
          ).on 'dblclick.brush', close_path
          #dispatch.brushstart()
        if active_region.extent.length > 1
          active_region.extent.pop()
        active_region.extent.push(get_mouse_domain(this))

        draw_path()

      close_path = ->
        w = d3.select(window)
        w.on('dblclick.brush', null).on 'mousemove.brush', null
        first_click = true
        extent = active_region.extent
        if extent.length == 2 and extent[0][0] == extent[1][0] and extent[0][1] == extent[1][1]
          extent.splice 0, extent.length
        d3.select('.region-' + active_region.id).on 'mousedown.brush', move_extent
        d3.selectAll('.background').style 'cursor', 'default'
        d3.selectAll('g.brush').style('pointer-events', 'all').on 'click.brush', null
        scope.drawing_region = false
        draw_region_labels()
        submit_answer()
        scope.$apply()
        # return dispatch.brushend()
        return

      draw_path = (move_thing) ->
        if move_thing
          d3.select(move_thing).attr 'd', (d) ->
            line(d) + 'Z'
        else
          d3.selectAll('.region-' + active_region.id).attr 'd', (d) ->
            line(d) + 'Z'

      redraw_region = (region) ->
        g = d3.selectAll('g.brush')

        fg = g.selectAll(".region-" + region.id).data([region.extent])

        fg.enter().append("path").attr("class", "extent region-" + region.id).attr("id", region.id).style("cursor", "move")

        fg.attr 'd', (d) ->
          if d.length > 0
            line(d) + 'Z'

        fg.on 'mousedown.brush', move_extent

      top = (region) ->
        if region.extent.length == 0
          return [-50,-50]
        _.min(region.extent, (p) -> p[1])

      draw_region_labels = () ->
        svg.selectAll('.region-label').remove()

        svg.selectAll('.region-label')
          .data(scope.regions)
          .enter()
          .append('text')
          .text((d) -> d.name)
          .attr("class", "region-label")
          .attr('text-anchor', 'middle')
          .attr("id", (d) -> "region-label-" + d.id)
          .attr("x", (d) -> x_scale top(d)[0])
          .attr("y", (d) -> y_scale(top(d)[1] - 1))
        return

      #returns the x, y cords of a mouse click on the domain scale
      get_mouse_domain = (target) ->
        [x_scale.invert(d3.mouse(target)[0]), y_scale.invert(d3.mouse(target)[1])]

      move_extent = ->
        _this = this
        region_id = parseInt(@id)
        active_region = _.find(scope.regions, (r) ->
          r.id == region_id
        )
        d3.event.stopPropagation()
        d3.event.preventDefault()
        if first_click and active_region.extent.length != 0
          d3.select(window).on('mousemove.brush', ->
            d3.selectAll('#region-label-' + region_id).remove()
            drag_extent _this
          ).on 'mouseup.brush', polybrush_drag_stop
          origin = get_mouse_domain(this)
        return

      move_anchor = (target) ->
        point = get_mouse_domain(target)
        if first_time
          active_region.extent.push point
          first_time = false
        else
          if within_bounds(point)
            active_region.extent.splice active_region.extent.length - 1, 1, point
          draw_path()
          set_sample_selected()
        return

      within_bounds = (point) ->
        rangeX = scale_extent(x_scale.range())
        rangeY = scale_extent(y_scale.range())
        _x = Math.max(rangeX[0], Math.min(rangeX[1], point[0]))
        _y = Math.max(rangeY[0], Math.min(rangeY[1], point[1]))
        point[0] == _x and point[1] == _y

      drag_extent = (target) ->
        point = get_mouse_domain(target)

        scaleX = point[0] - (origin[0])
        scaleY = point[1] - (origin[1])
        fail = false
        origin = point

        updateExtentPoint = (p) ->
          p[0] += scaleX
          p[1] += scaleY
          return

        _i = 0
        _len = active_region.extent.length
        while _i < _len
          p = active_region.extent[_i]
          updateExtentPoint p
          _i++

        check_region_bounds = (p) ->
          if !within_bounds(p)
            fail = true
          fail

        _j = 0
        _len1 = active_region.extent.length
        while _j < _len1
          p = active_region.extent[_j]
          check_region_bounds p
          _j++
        if fail
          return
        draw_path target
        # dispatch.brush mode: 'move'
        set_sample_selected()

      polybrush_drag_stop = ->
        w = d3.select(window)
        w.on('mousemove.brush', null).on 'mouseup.brush', null
        wasDragged = true
        draw_region_labels()
        submit_answer()
        # dispatch.brushend()

      is_within_any_extent = (x, y) ->
        response = false
        _.forEach scope.regions, (region) ->
          ret = is_within_region_extent(x, y, region.id)
          if ret
            response = true
            return
          return
        response

      is_within_region_extent = (x, y, region_id) ->
        region = _.find(scope.regions, (r) ->
          r.id == region_id
        )
        len = region.extent.length
        j = len - 1
        ret = false
        i = _i = 0
        _len = region.extent.length
        while _i < _len
          p1 = region.extent[i]
          p2 = region.extent[j]
          if ((p1[1] > y) != (p2[1] > y) and x < (p2[0] - p1[0]) * (y - p1[1]) / (p2[1] - p1[1]) + p1[0])
            ret = !ret
          j = i
          i = ++_i
        ret

      polybrush_clear = (region_id) ->
        region = _.find(scope.regions, (r) ->
          r.id == region_id
        )
        if region
          region.extent.splice 0, region.extent.length
        return

      ###########################
      ###### polybrush end ######
      ###########################

      svg.append("svg:g")
        .attr("class", "brush")
        .call(polybrush, false)

      sample_drag = d3.behavior.drag()
        .on('drag', (d) -> drag_sample(d, d3.select(this)))
        .on('dragend', (d) -> sample_drag_stop(d, d3.select(this)))

      sample_x = (d) ->
        x_scale d.x

      sample_y = (d) ->
        y_scale d.y

      sample_r = (d) ->
        25

      sample_label = (d) ->
        $filter('sampleDisplayLabel')(d.id)

      sample_color = (d) ->
        "#3498db"

      #prevents the builder preview from re-drawing the sample-drop-zone
      sample_drop_zones = svg.selectAll('.sample-drop-zone')

      if !redraw && sample_drop_zones[0].length == 0
        svg.append('rect')
          .attr('class', 'sample-drop-zone')
          .attr("x", width - drop_zone_width)
          .attr("y", 0)
          .attr("width", drop_zone_width)
          .attr("height", height)
          # .attr('opacity', 0.05);
          .attr("fill", "#f0f0f0")

        svg.append('line')
          .attr('class', 'sample-drop-line')
          .style('stroke', 'red')
          .attr('opacity', 0.5)
          .attr('x1', x_scale domain)
          .attr('y1', y_scale 0)
          .attr('x2', x_scale domain)
          .attr('y2', y_scale domain)

      #remove start and end ticks for the axes
      svg.selectAll('.tick').each (d, i) ->
        if d == 0 || d == 100
          @remove()
        return

      if scope.surveyHelper.isBuilderPreview
        svg.selectAll('.node').remove()
        # body...

      samples = svg.selectAll('.node')
        .data(survey_samples)
        .enter()
        .append('g')
        .attr("class", "node")
        .attr("id", (d) -> "node-" + d.id)
        .attr("transform", (d) -> "translate(" + sample_x(d) + "," + sample_y(d) + ")")
        .call(sample_drag)

      sample_circles = samples.append('circle')
        .attr('class', 'drag_sample')
        .attr('id', (d, i) -> "sample-" + d.id)
        .attr('r', sample_r)
        .attr('fill', sample_color)
        .attr('stroke', sample_color)
        .style("cursor", "move")

      sample_labels = samples.append('text')
        .text(sample_label)
        .attr('text-anchor', 'middle')
        .style('-webkit-user-select','none')
        .style("cursor", "move")
        .attr('y', 5)

      drag_sample = (d, sample) ->
        d.x += x_scale.invert(d3.event.dx)
        d.y += y_scale.invert(d3.event.dy)

        if !check_sample_bounds(d.x, d.y)
          return

        sample.attr("transform", (d) -> "translate(" + sample_x(d) + "," + sample_y(d) + ")")
        return

      check_sample_bounds = (x, y) ->
        if !scope.placing_samples && (x <= x_scale.invert(25) || x >= domain - x_scale.invert(25) || y <= y_scale.invert(25) || y >= domain - y_scale.invert(25))
          return false
        if x <= x_scale.invert(25) || x >= domain + x_scale.invert(drop_zone_width - 25) || y <= y_scale.invert(25) || y >= domain - y_scale.invert(25)
          return false
        else
          return true
        return

      sample_drag_stop = (d, sample) ->
        cords = get_node_range_cords(sample)
        cords_dom = get_node_domain_cords(sample)

        sample_within_domain(cords_dom, d)

        if all_samples_placed() && scope.placing_samples
          d3.select('.napping-canvas').attr('width', width - drop_zone_width)
          remove_drop_zone()
            # .transition()
            # .attr('width', width - drop_zone_width)
            # .each('end', remove_drop_zone)

        if all_samples_placed()
          submit_answer()

        set_sample_selected(cords.x, cords.y)
        return

      remove_drop_zone = () ->
        d3.select('.sample-drop-zone').remove()
        d3.select('.sample-drop-line').remove()
        scope.placing_samples = false
        scope.$apply()
        return

      sample_within_domain = (cords, d) ->
        sample = _.find(survey_samples, (s) -> s.id == d.id)
        if cords.x > 0 && cords.x < domain && cords.y > 0 && cords.y < domain
          sample.placed = true
        else
          sample.placed = false

      all_samples_placed = () ->
        _.find(survey_samples, (s) -> s.placed != true) == undefined

      set_sample_selected = () ->
        # svg.selectAll('.drag_sample').classed 'selected', (d) ->
        #   cords = get_node_domain_cords(d3.selectAll('#node-' + d.id))
        #   if is_within_any_extent(cords.x, cords.y)
        #     true
        #   else
        #     false
        return

      # Returns the sample cordinates on our range scale
      get_node_range_cords = (node) ->
        transf = node.attr("transform")
        cords = transf.substring(10, transf.length - 1).split(",")
        x_cord = parseFloat(cords[0])
        y_cord = parseFloat(cords[1])
        return { x: x_cord, y: y_cord }

      # Returns the sample cordinates on our domain scale
      get_node_domain_cords = (node) ->
        node_range_cords = get_node_range_cords(node)
        x_domain_cord = x_scale.invert(node_range_cords.x)
        y_domain_cord = y_scale.invert(node_range_cords.y)
        return { x: x_domain_cord, y: y_domain_cord }

      if redraw
        scope.placing_samples = false
        _.forEach(scope.question.regions, (region) ->
          redraw_region(region)
        )
        draw_region_labels()
        set_sample_selected()

      submit_answer = () ->
        answers = []
        _.forEach(survey_samples, (sample) ->
          node_domain_cords = get_node_domain_cords(d3.selectAll('#node-' + sample.id))

          answer =  { sample_id: sample.id, x: node_domain_cords.x, y: domain - node_domain_cords.y, region_ids: [] }
          _.forEach(scope.regions, (region) ->
            if is_within_region_extent(node_domain_cords.x, node_domain_cords.y, region.id)
              answer.region_ids.push(region.id)
          )
          answers.push(answer)
        )
        scope.question.regions = scope.regions
        scope.question.created_regions_count = created_regions_count
        scope.question.recordAnswer(answers)
        return

      scope.add_region = () ->
        created_regions_count++
        new_region = { id: created_regions_count, extent: []}
        scope.regions.push(new_region)
        submit_answer()
        return

      scope.draw_region = (region_id) ->
        scope.drawing_region = true
        if d3.selectAll('g.brush')[0].length > 0
          d3.selectAll('g.brush').call(polybrush_draw, region_id)

      scope.region_name_changed= () ->
        draw_region_labels()
        submit_answer()
        return

      scope.validate_region_name = (region, question) ->
        error = false
        if question.error_messages && question.error_messages.length > 0
          _.forEach(question.error_messages[0].value, (r_id) ->
            if r_id == region.id
              error = true
          )
        if error
          return 'has-error'
        else
          return ''

      scope.delete_region = (region_id) ->
        polybrush_clear(region_id)

        _.forEach(survey_samples, (s) ->
          cords = get_node_range_cords(d3.selectAll('#node-' + s.id))
          set_sample_selected(cords.x, cords.y)
        )

        d3.selectAll('.region-' + region_id).remove()
        d3.selectAll('#region-label-' + region_id).remove()

        _.remove(scope.regions, (r) -> r.id == region_id)

        submit_answer()
        return

      return
  )


export default nappingCanvas
