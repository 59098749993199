import * as angular from "angular"

directives = angular.module('redjade.survey_taker.directives', [
])
  .directive('multiSampleSlider', require("./multiSampleSlider.directive").default)
  .directive('nappingCanvas', require("./nappingCanvas.directive").default)
  .directive('targetCanvas', require("./targetCanvas.directive").default)
  .directive('rangeSliderPreviousSampleLabels', require("./rangeSliderPreviousSampleLabels.directive").default)
  .directive('slider', require("./slider.directive").default)
  .run(($templateCache) ->
    "ngInject"
    $templateCache.put('survey_taker/question_types/napping_canvas.html', require("./napping_canvas.html"))
    $templateCache.put('survey_taker/question_types/target_canvas.html', require("./target_canvas.html"))
  )

export default directives