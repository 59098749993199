surveyOrchestratorCtrl = (
  $q
  $scope
  $location
  surveyHelper
  Restangular
  $rootScope
  surveyDataAdapter
  $state
  $offline
  $timeout
  $interval
  appInfo
) ->
  "ngInject"


  data = {
    questionnaire_data: null
  }
  $scope.show_taker = false

  #when the page is refreshed check if a survey is already in progress
  doInProgressCheck = () ->
    promises = [
      surveyDataAdapter.inProgressCheck(),
      surveyDataAdapter.fetchMeta()
    ]

    $q.all(promises).then (responses) ->
      in_prog_data = responses[0]
      $scope.meta = responses[1]
      surveyHelper.populateFromServer(in_prog_data)
      addStyles(surveyHelper.questionnaire.config) if surveyHelper.questionnaire.config
      $rootScope.$broadcast "reloadTakerEvent", 0
      $scope.show_taker = true
    , (errors) ->
      $state.go('sample_entry')

  insertRule = (stylesheet, selector, rule) ->
    if stylesheet.insertRule
      stylesheet.insertRule(selector + rule, stylesheet.cssRules.length)
    else if (stylesheet.addRule)
      stylesheet.addRule(selector, rule, -1)

  addStyles = (config) ->
    if config.question_font_size
      stylesheet = document.styleSheets[0]
      selector = "h4[variable-replacer]"
      rule = "{font-size:"+config.question_font_size+"px}"
      insertRule(stylesheet, selector, rule)
    if config.response_font_size
      stylesheet = document.styleSheets[0]
      selector = ".question label, .question .answer, .question .slider-label"
      rule = "{font-size:"+config.response_font_size+"px}"
      insertRule(stylesheet, selector, rule)
    if config.question_spacing
      stylesheet = document.styleSheets[0]
      selector = ".questionnaire-taker .taker-question-block .question"
      rule = "{margin-bottom:"+config.question_spacing+"px !important}"
      insertRule(stylesheet, selector, rule)

  stopQuestionnaire = ->
    return unless surveyHelper.serving_id
    surveyDataAdapter.stopServing(surveyHelper.serving_id).then ->
      window.location.reload()
      return
    return

  submitQuestionnaire = ->
    $scope.show_taker = false
    if $offline.state == 'up'
      surveyDataAdapter.submitCompleteQuestionnaire(data.questionnaire_data).then (result) ->
        if result.status == 'stop'
          $state.go('completed_survey')
        else
          doInProgressCheck()
      , (errors) ->
        $scope.show_taker = true
        submitFailure()
    else
      $scope.show_taker = true
      submitFailure()
    return
  $scope.submitQuestionnaire = submitQuestionnaire

  submitFailure = ->
    $scope.submit_failure = true
    $offline.on('up', (event) ->
      submitQuestionnaire()
      return
    )
    return

  questionnaireCompleteEvent = $scope.$on "questionnaireStopEvent", (event, questionnaire_data) ->
    data.questionnaire_data = questionnaire_data
    stopQuestionnaire()
    return

  questionnaireCompleteEvent = $scope.$on "questionnaireCompleteEvent", (event, questionnaire_data) ->
    data.questionnaire_data = questionnaire_data
    submitQuestionnaire()
    return

  answerSelectedEvent = $scope.$on "answerSelectedEvent", (event, answer_data) ->

    surveyDataAdapter.submitAnswer(answer_data)

  pageStartedEvent = $rootScope.$on "pageStartedEvent", (event, page_data) ->
    surveyDataAdapter.pageStart(page_data)

  $scope.$on '$destroy',  ->
    questionnaireCompleteEvent()
    answerSelectedEvent()
    pageStartedEvent()


  doInProgressCheck()

export default surveyOrchestratorCtrl
