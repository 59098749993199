import * as _ from "lodash"

class Association extends Taker.BaseQuestion
  @icon: "fa fa-adjust"
  @display_name: "Association"
  @question_type: "association"
  @description: "The association question will display one response at a time and the participant will then select one of the predefined keys (Agree/Disagree, Important/Not Important, Yes/No etc.). RedJade will record the selection and time to make selection for each response."

  validate: ->
    @error_messages = []
    if @selected_answers.length != @answers.length
      @error_messages.push {type: "required"}
    @error_messages.length is 0

  recordAnswer: ->
    @publishAnswerSelectedEvent()

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.Association = Association
