import * as angular from "angular"


ui_completed_survey = angular.module('redjade.survey_taker.ui.completed_survey', [
])
  .controller('completedSurveyCtrl', require("./completedSurvey.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/completed_survey/completed_survey.html', require("./completed_survey.html"))
  )




export default ui_completed_survey
