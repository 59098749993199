class QuestionnaireTimer extends Taker.BasePageElement
  @icon: "fa fa-clock-o"
  @display_name: "Questionnaire Timer"
  @question_type: "questionnaire_timer"
  @has_time: true
  @validations: [
    {prop: "config.time", type: "numeric"}
  ]

  validate: ->
    return true unless @.is_visible
    @timer_complete == true

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.QuestionnaireTimer = QuestionnaireTimer
