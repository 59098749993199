class NumericGridQuestion extends Taker.BaseQuestion
  @icon: "fa fa-calculator"
  @display_name: "Numeric Grid"
  @question_type: "numeric_grid"

  validate: ->
    @error_messages = []
    if @validations && @is_visible
      bad_numbers = false
      bad_min = false
      bad_max = false
      config = @config
      _.each @groups, (group) ->
        group.selected_answers = [] unless group.selected_answers
        sas = _.compact(group.selected_answers)
        _.each sas, (sa) ->
          if sa.score && isNaN(parseFloat(sa.score))
            bad_numbers = true
          else
            bad_min = bad_min or (config.min and sa.score < parseFloat(config.min))
            bad_max = bad_max or (config.max and sa.score > parseFloat(config.max))
          return
      @error_messages.push {type: "numeric"} if bad_numbers
      @error_messages.push {type: "min", value: "#{@config.min}"} if bad_min
      @error_messages.push {type: "max", value: "#{@config.max}"} if bad_max

      _.each @validations, (rule) =>
        if rule.type is "required"
          found_missing = false
          _.each @groups, (group) =>
            if group.selected_answers.length < @answers.length
              found_missing = true
            _.each group.selected_answers, (sa) ->
              if !sa || (!sa.score && sa.score != 0)
                found_missing = true
          @error_messages.push {type: "required"} if found_missing

        if rule.type is "sum to"
          found_wrong_sum = false
          _.each @groups, (group) ->
            sum = 0
            sas = _.compact(group.selected_answers)
            _.each sas, (sa) ->
              if !isNaN(parseFloat(sa.score))
                sum = sum + parseFloat(sa.score)
            if sum != parseFloat(rule.equal)
              found_wrong_sum = true

          @error_messages.push {type:"sum", value: "#{rule.equal}"} if found_wrong_sum

    @error_messages.length is 0

  answeringTimeout = null
  recordAnswer: (selected_answer, answer, group) ->
    selected_answer.id = answer.id
    selected_answer.answered_at = Date.now()
    @validate() if((@error_messages && @error_messages.length > 0) || (group.selected_answers.length == @answers.length))
    @publishAnswerSelectedEvent()

  constructor: (data) ->
    init_data = {selected_answers: []}
    # _.each data.answers, (answer) -> init_data.selected_answers.push({id: answer.id, score: null}) #initialize each selected answer to null
    _.merge(init_data, data)
    super init_data

window.Taker.NumericGridQuestion = NumericGridQuestion
