class ReviewTable extends Taker.BasePageElement
  @icon: "fa fa-table"
  @display_name: "Review Table"
  @question_type: "review_table"
  @has_font_size: false

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.ReviewTable = ReviewTable
