import * as _ from "lodash"

sampleEntryCtrl = (
  $scope
  surveyHelper
  Restangular
  $sce
  $q
  languageHelper
  surveyDataAdapter
  $state
  $stateParams
  QuestionnaireSvc
  $interval
  $timeout
  appInfo
) ->
  "ngInject"

  $scope.loading = true
  $scope.languageHelper = languageHelper
  $scope.model = {
    focus: [false] # we toggle this after loading is true so that the element exists when we try to focus it
  }

  $scope.code_entered = () ->
    $scope.errors = null
    payload =
      serving_codes: $scope.model.serving_codes
    $scope.loading = true
    surveyDataAdapter.sampleCodeEntered(payload).then ((data) ->
      surveyHelper.is_first_sample = false
      $state.go("questionnaire")
    ), (res) ->
      $scope.loading = false
      $scope.errors = res.data.messages
      $scope.model.focus[0] = true

  $scope.renderHtml = (html_code) ->
    $sce.trustAsHtml(html_code)

  setScreenDefaults = ->
    screens = $scope.meta.screens
    locale = $scope.locale
    await languageHelper.load(locale)
    if !screens[locale]
      screens[locale] = {}
    if !screens[locale].SAMPLE_START_HTML
      screens[locale].SAMPLE_START_HTML = languageHelper[locale].SAMPLE_START_HTML
    if !screens[locale].sample_entry_input
      screens[locale].sample_entry_input = languageHelper[locale].sample_entry_input
    if !screens[locale].palate_cleanse
      screens[locale].palate_cleanse = languageHelper[locale].palate_cleanse
    if !screens[locale].SKIP_VALIDATION_CONFIRMATION_HTML
      screens[locale].SKIP_VALIDATION_CONFIRMATION_HTML = languageHelper[locale].SKIP_VALIDATION_CONFIRMATION_HTML

  fetchData = ->
    promises = []
    promises.push(surveyDataAdapter.fetchCurrentParticipant())
    promises.push(surveyDataAdapter.fetchMeta())
    $q.all(promises).then ((responses) ->
      $scope.model.input_count = responses[0].max_possible_codes
      $scope.sample_codes = _.map(responses[0].valid_servings, (s) -> s.code)[0]
      $scope.participant = responses[0]
      $scope.locale = responses[0].locale
      $scope.is_done_for_day = responses[0].is_done_for_day
      $scope.allow_serving_retake = responses[1].allow_serving_retake
      # after last sample is completed it should switch to enforce_serving_order=false so that samples can be restarted before clicking I'm Finished
      $scope.participant.enforce_serving_order = false if $scope.is_done_for_day && $scope.allow_serving_retake
      $scope.meta = responses[1]

      current_serving = _.find($scope.participant.valid_servings, (serving) -> serving.status == 'pending') || $scope.participant.valid_servings[0]
      $scope.current_serving = current_serving
      if current_serving
        $scope.sample_entry_mode = current_serving.sample_entry_mode
        switch $scope.sample_entry_mode
          when "skip"
            $scope.model.serving_codes = current_serving.code.split(', ')
            $scope.code_entered()
          when "confirm"
            if($scope.participant.enforce_serving_order)
              $scope.model.serving_codes = current_serving.code.split(', ')
            else
              $scope.model.serving_codes = []
          when "verify"
            $scope.model.serving_codes = []
      else
        # it's probably a cutting since the status check didn't take us to complete, allow them to re-enter a specific sample
        $scope.sample_entry_mode = 'verify'
        $scope.model.serving_codes = []


      setScreenDefaults()

      $scope.loading = false
      $scope.model.focus[0] = true
    )

  $scope.selectServing  = (serving) ->
    $scope.participant.valid_servings.forEach( (s) => s.selected = false)
    $scope.model.serving_codes = serving.code.split(', ')
    serving.selected = true

  $scope.signOutParticipant = ->
    $state.go("completed_survey")

  $scope.$on "reloadSampleEntryEvent", () ->
    fetchData()

  fetchData()


export default sampleEntryCtrl
