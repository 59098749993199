import * as _ from "lodash"
objectHash = require("object-hash")

chunk = (
) ->
  "ngInject"

  cacheIt = (func) ->
    cache = {}
    (collection, items_per_chunk, transpose = false) ->

      items_per_chunk ||= 1
      collection_length = collection.length || 0
      hash = objectHash(collection) + items_per_chunk + transpose

      if cache[hash]
        cache[hash]
      else
        chunks = func(collection, items_per_chunk)
        chunks = _.map(_.unzip(chunks), (x) => _.reject(x, _.isUndefined)) if transpose
        (cache[hash] = chunks)

  return cacheIt _.chunk


export default chunk

