import * as angular from "angular"
import * as _ from "lodash"

// @ts-ignore
window.Taker = {}

class BaseElement {
  zipper_cid: any
  config: Record<string, any> & {
    zipper: boolean
    answers_source_selected: boolean
    questionnaireMediaResponseId?: number
  }
  sample_id: string

  static is_page_element: boolean
  static description: string
  static display_name: string
  static file_name: string
  static question_type: string

  static initClass() {
    this.is_page_element = false
    this.description = null
  }

  isPageElement(): boolean {
    //@ts-ignore
    return this.constructor.is_page_element
  }

  description(): string {
    // @ts-ignore
    return this.constructor.description
  }

  getDisplayName(): string {
    //@ts-ignore
    return this.constructor.display_name
  }

  template(): string {
    // @ts-ignore
    return this.constructor.file_name ? this.constructor.file_name : this.constructor.question_type
  }

  takerPath() {
    if (this.config.zipper) {
      return "survey_taker/question_types/zipper.html"
    } else {
      return this.unzipperedTakerPath()
    }
  }

  unzipperedTakerPath() {
    return `survey_taker/question_types/${this.template()}.html`
  }

  getInjector() {
    return angular.element(document).injector()
  }

  validate() {
    return true
  }

  recordAnswer(answer): any {
    return true
  }

  findQuestionByCID(cid, sample_id = null) {
    const surveyHelper = getInjector<SurveyHelper>("surveyHelper")
    return surveyHelper.findQuestionByCID(cid, sample_id)
  }

  findAnswerByID(id) {
    const surveyHelper = getInjector<SurveyHelper>("surveyHelper")
    let answer = null
    _.each(surveyHelper.questionnaire.pages, function (page) {
      _.each(page.questions, function (q) {
        _.each(q.answers, function (a) {
          if (a.id === id) {
            answer = a
            return false
          }
        })
        if (answer) {
          return false
        }
      })
      if (answer) {
        return false
      }
    })
    return answer
  }

  publishAnswerSelectedEvent() {
    const rootScope = getInjector("$rootScope")
    this["has_been_seen"] = true
    if (this.zipper_cid) {
      const zipper = this.syncZipperSelectedAnswers()
      const surveyHelper = getInjector<SurveyHelper>("surveyHelper")
      surveyHelper.answerSelected(this)
      rootScope.$broadcast("answerSelectedEvent", zipper, this.sample_id)
    } else {
      rootScope.$broadcast("answerSelectedEvent", this)
    }
  }

  syncZipperSelectedAnswers() {
    const zipper = this.findQuestionByCID(this.zipper_cid)
    zipper.selected_answers = _.reduce(
      zipper.unzipped_questions,
      function (selected_answers, unzipped) {
        const sa = unzipped.selected_answers.map(function (sa) {
          const a = angular.copy(sa)
          // make the zipper answers consistent with its own answer ids (instead of having the cid of the unzipped questions)
          if (a.zipper_answer_id) {
            a.id = a.zipper_answer_id
            delete a.zipper_answer_id
          } else if (sa.id) {
            // otherwise it's probably an "anonymous" answer like a numeric/text answer or text question and no reconciliation is necessary
            // kludge so we dont have to convert selects to send the entire answer models
            const my_answer = _.find(unzipped.answers, ans => ans.id === sa.id)
            a.id = my_answer.zipper_answer_id
          }
          return a
        })
        selected_answers[unzipped.sample_id] = sa
        return selected_answers
      },
      {},
    )
    return zipper
  }

  constructor(data) {
    this.isPageElement = this.isPageElement.bind(this)
    this.description = this.description.bind(this)
    _.merge(this, data)
  }
}
BaseElement.initClass()

window.Taker.BaseElement = BaseElement

export default BaseElement
