import { formatDisplayTime } from "~angular/src/rj_shared/utils/globalUtils"

const questionnaireTimerCtrl = function ($scope, $interval, surveyHelper) {
  "ngInject"

  $scope.init = function (question) {
    $scope.cid = question.cid
    $scope.timer_running = false
    if (question.timer_complete) {
      return
    }

    if (question.config.page_id === "manual") {
      $scope.manual_start = true
      $scope.registerRequiredAction($scope.cid)
      if (question.manual_start_time) {
        startTimer(question, question.manual_start_time)
        return ($scope.timer_running = true)
      }
    } else {
      const start_time = __guard__(surveyHelper.findInProgressPageById(question.config.page_id), x => x.start_time)
      return startTimer(question, start_time)
    }
  }

  $scope.formatDisplayTime = formatDisplayTime

  $scope.manualStartTimer = question => startTimer(question, Date.now())

  var startTimer = function (question, start_time) {
    let stop
    if (!question.manual_start_time) {
      question.manual_start_time = start_time
      question.publishAnswerSelectedEvent()
    }
    const seconds_to_add = question.config.time || 300
    const computed_time_in_milli = start_time + parseInt(seconds_to_add) * 1000
    let minus = (computed_time_in_milli - Date.now()) / 1000

    if (minus <= 0) {
      iAmFinished(question, null)
    } else {
      $scope.display_time = minus.toFixed(0)
      $scope.registerRequiredAction($scope.cid)
    }

    return (stop = $interval(function () {
      $scope.timer_running = true
      minus = (computed_time_in_milli - Date.now()) / 1000
      $scope.display_time = minus.toFixed(0)
      if (minus < 0) {
        iAmFinished(question, stop)
      }
    }, 1000))
  }

  var iAmFinished = function (question, stop) {
    $scope.timer_running = false
    question.timer_complete = true
    $scope.unRegisterRequiredAction($scope.cid)
    $scope.display_time = 0
    $interval.cancel(stop)
    question.publishAnswerSelectedEvent()
    if (question.config.auto_advance) {
      return $scope.nextPage()
    }
  }

  return $scope.$on("$destroy", () => $scope.unRegisterRequiredAction($scope.cid))
}

export default questionnaireTimerCtrl

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
