class NumericQuestion extends Taker.BaseQuestion
  @icon: "fa fa-calculator"
  @display_name: "Numeric"
  @question_type: "numeric"

  answeringTimeout = null

  validate: ->
    @error_messages = []
    if @is_visible && !@config.zipper
      if @selected_answers[0] && @selected_answers[0].score?
        if isNaN(parseFloat(@selected_answers[0].score))
          @error_messages.push {type: "numeric"}
        else
          @error_messages.push {type: "min", value: "#{@config.min}"}  if @config.min and @selected_answers[0].score < parseFloat(@config.min)
          @error_messages.push {type: "max", value: "#{@config.max}"}  if @config.max and @selected_answers[0].score > parseFloat(@config.max)

      if @validations
        _.each @validations, (rule) =>
          if rule.type is "required"
            if @selected_answers.length < 1 || !@selected_answers[0].score? || @selected_answers[0].score.length < 1
              @error_messages.push {type: "required"}

          if rule.type is "range" && @selected_answers[0]
            @error_messages.push {type: "min", value: "#{rule.min}"}  if rule.min and @selected_answers[0].score < parseFloat(rule.min)
            @error_messages.push {type: "max", value: "#{rule.max}"}  if rule.max and @selected_answers[0].score > parseFloat(rule.max)
    @error_messages.length is 0

  recordAnswer: (answer) ->
    @selected_answers = [{score: answer, answered_at: Date.now()}]
    @validate()
    @publishAnswerSelectedEvent()
    @current_answer = @selected_answers[0]

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data
    @selected_answers ||= []
    @current_answer = @selected_answers[0]

window.Taker.NumericQuestion = NumericQuestion
