class ConjointQuestion extends Taker.ChoiceModelQuestion
  @icon: "fa fa-dot-circle-o"
  @display_name: "Conjoint"
  @question_type: "conjoint"

  getProfile: (trial_num, card_num, is_preview = false, builderHelper = null) ->
    profile = {id: null, levels: []}
    profile_num = (trial_num*this.config.conjoint.cards_per_trial)+card_num
    if this?.question_profiles?.length > 0
      profile.id = this.question_profiles[profile_num].id
      level_ids = this.question_profiles[profile_num].question_level_ids
      this.question_attributes.forEach((attr) => profile.levels.push(attr.question_levels.find((ql) => level_ids.includes(ql.id))))
    else if is_preview
      # called by builder when previewing questionnaire OR when previewing event (no builder)
      builderHelper?.ignore_pending_changes = true
      profile.id = profile_num
      this.question_attributes.forEach((attr) => profile.levels.push(attr.question_levels[Math.floor(Math.random() * attr.question_levels.length)]))
      builderHelper?.ignore_pending_changes = false
    profile


  validate: ->
    @error_messages = []
    if @validations && @is_visible
      _.each @validations, (rule) =>
        if rule.type is "required"
          valid_answers = this.validAnswers()
          if (valid_answers.length < this.config.conjoint.trials_per_participant)
            answered_trial_nums = valid_answers.map((a) -> a.trial_num).sort((a,b) -> a-b)
            answered_trial_nums.splice(0, 0, 0)
            for i in [0..answered_trial_nums.length]
              if ( answered_trial_nums[i + 1] - answered_trial_nums[i] != 1) #find the first missing trial
                this.config.conjoint.current_trial_number = i
                break

            @error_messages.push {type: "required"}
    @error_messages.length is 0

  validAnswers: ->
    if this.config.no_preference_mode != 'dual_response'
      return this.selected_answers

    return this.selected_answers.filter((s) -> s.selected_profile_id != null && s.no_preference != undefined && s.no_preference != null)

  recordAnswer: (answer, trial_num) ->
    is_deselect = this.selected_answers.some((a) -> a.selected_profile_id == answer.id; )
    this.selected_answers = this.selected_answers.filter((a) -> a.trial_num != trial_num+1) # delete any previous trial answers
    this.pushAnswer(is_deselect, trial_num+1, undefined, answer.id)

  recordNoPreferenceAnswer: () ->
    trial_num = this.config.conjoint.current_trial_number + 1
    is_deselect = this.selected_answers.find((a) -> a.trial_num == trial_num && a.no_preference)
    this.selected_answers = this.selected_answers.filter((a) -> a.trial_num != trial_num)
    this.pushAnswer(is_deselect, trial_num, true)

  recordNoPreferenceDualAnswer: (no_preference = true) ->
    trial_num = this.config.conjoint.current_trial_number + 1
    is_deselect = this.selected_answers.find((a) -> a.trial_num == trial_num && a.no_preference == no_preference)
    answer = this.selected_answers.find((a) -> a.trial_num == trial_num)
    if answer
      answer.no_preference = if is_deselect then undefined else no_preference
    unless is_deselect
      this.config.show_no_pref = false
      this.nextTrial()
    @publishAnswerSelectedEvent()

  pushAnswer: (is_deselect, trial_num, no_preference = undefined, selected_profile_id = null) ->
    if is_deselect
      this.config.show_no_pref = false
    else
      answer =
        selected_profile_id: selected_profile_id
        trial_profiles_ids: [...Array(this.config.conjoint.cards_per_trial).keys()].map((card_num) => this.getProfile(trial_num-1, card_num).id)
        trial_num: trial_num
        answered_at: Date.now()
      answer.no_preference = no_preference
      this.selected_answers.push(answer)
      this.config.show_no_pref = true
      if this.config.no_preference_mode != 'dual_response'
        this.nextTrial()
    @publishAnswerSelectedEvent()

  nextTrial: =>
    this.config.conjoint.current_trial_number++ unless this.config.conjoint.current_trial_number >= this.config.conjoint.trials_per_participant-1
    trial_num = this.config.conjoint.current_trial_number + 1
    answer = this.selected_answers.find((a) -> a.trial_num == trial_num)
    if answer && answer.selected_profile_id
      this.config.show_no_pref = true

  previousTrial: ->
    this.config.show_no_pref = true
    this.config.conjoint.current_trial_number-- unless this.config.conjoint.current_trial_number <= 0
    document.getElementById('trial-previous-' + this.id).blur()

  isAnswerSelected: (answer) ->
    if (answer && this.selected_answers.find((a)=> a.selected_profile_id == answer.id)) then true else false

  isAnswerNoPref: (answer = true) ->
    trial_num = this.config.conjoint.current_trial_number + 1
    this.selected_answers.find((a)=> a.trial_num == trial_num && a.no_preference == answer)

  cardBootstrapSize: () ->
    label_counter = if this.config.conjoint.hide_attribute_labels then 0 else 1
    Math.floor(12/(this.config.conjoint.cards_per_trial+label_counter))

  attributeHasMedia: (levels, locale) ->
    levels.filter((l) => l?.config?.media?[locale]?.source_url).length > 0

  constructor: (data) ->
    init_data = {
      selected_answers: []
      config:
        conjoint:
          current_trial_number: 0
    }
    _.merge(init_data, data)
    super init_data
    # super data

window.Taker.ConjointQuestion = ConjointQuestion
