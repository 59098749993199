class RangeTargetQuestion extends Taker.BaseQuestion
  @icon: "fa fa-arrows-h"
  @display_name: "Target"
  @question_type: "target"

  recordAnswer: (answer) ->
    return if answer == undefined
    answer.answered_at = Date.now()
    if @config.zipper_merge
      @selected_answers = {} if $.isArray(@selected_answers)
      if answer._destroy
        delete @selected_answers[answer.sample_id.toString()]
      else if answer.sample_id && @config.min <= answer?.score <= @config.max
        @selected_answers[answer.sample_id.toString()] = [answer]
    else
      if answer._destroy
        @selected_answers = []
      else
        @selected_answers = [answer]
  
  # getSamples: (samples) ->
  #   if(samples.length==0)
  #     return [{}] # no samples needs 1 subject
  #   else if(samples.length==1 || @config.zipper_merge)
  #     return samples
  #   else if(@config.zipper)
  #     return samples.filter( (s) => s["id"] == this.sampleId )
  #   else
  #     return [{}] #multi-sample with out zippering needs 1 subject

  # @parsed_sammples = null
  # getSamples: (samples) ->
  #   if(@parsed_samples)
  #   else if(samples.length==0)
  #     @parsed_sammples = [{}] # no samples needs 1 subject
  #   else if(samples.length==1 || @config.zipper_merge)
  #     @parsed_sammples = samples
  #   else if(@config.zipper)
  #     @parsed_sammples = samples.filter( (s) => s["id"] == this.sampleId )
  #   else
  #     @parsed_sammples = [{}] #multi-sample with out zippering needs 1 subject
    
  #   return @parsed_samples

    @validate() if @error_messages and @error_messages.length > 0
    @publishAnswerSelectedEvent()

  constructor: (data) ->
    init_data = {
      previous_sample_groups: { '-20': [] }
    }
    _.merge(init_data, data)
    super init_data


window.Taker.RangeTargetQuestion = RangeTargetQuestion
