import { formatDisplayTime } from "~angular/src/rj_shared/utils/globalUtils"

const palateCleanseCtrl = function (
  $scope,
  $state,
  $timeout,
  surveyDataAdapter,
  $q,
  $sce,
  Restangular,
  languageHelper,
) {
  "ngInject"

  $scope.languageHelper = languageHelper

  $scope.renderHtml = html_code => $sce.trustAsHtml(html_code)
  $scope.formatDisplayTime = formatDisplayTime

  const setScreenDefaults = async function () {
    const { screens } = $scope.meta
    const { locale } = $scope
    await languageHelper.load(locale)
    if (!screens[locale]) {
      screens[locale] = {}
    }
    if (!screens[locale].PALATE_CLEANSE_SCREEN_HTML) {
      return (screens[locale].PALATE_CLEANSE_SCREEN_HTML = languageHelper[locale].PALATE_CLEANSE_SCREEN_HTML)
    }
  }

  const fetchData = function () {
    const promises = []
    promises.push(surveyDataAdapter.fetchCurrentParticipant({ lite: true }))
    promises.push(surveyDataAdapter.fetchMeta())
    return $q.all(promises).then(
      function (responses) {
        let timer
        surveyDataAdapter.fetchPalateCleanseTime().then(res => ($scope.countDown = res))
        $scope.locale = responses[0].locale
        $scope.meta = responses[1]
        $scope.countDown = 10

        setScreenDefaults()

        return (timer = setInterval(function () {
          if ($scope.countDown > 0) {
            $scope.countDown--
          } else {
            clearInterval(timer)
            surveyDataAdapter.fetchPalateCleanseTime().then(function (res) {
              if (res > 0) {
                return $state.reload()
              } else {
                return $state.go("sample_entry")
              }
            })
          }
          $scope.$apply()
        }, 1000))
      },
      errors => $state.go("sample_entry"),
    )
  }

  return fetchData()
}

export default palateCleanseCtrl
