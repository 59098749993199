import * as angular from "angular"


ui_palate_cleanse = angular.module('redjade.survey_taker.ui.palate_cleanse', [
])
  .controller('palateCleanseCtrl', require("./palateCleanse.controller.ts").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/palate_cleanse/palate_cleanse.html', require("./palate_cleanse.html"))
  )




export default ui_palate_cleanse
