import * as angular from "angular"


ui_consent_page = angular.module('redjade.survey_taker.ui.consent_page', [

])
  .controller('consentPageCtrl', require("./consentPage.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/consent_page/consent_page.html', require("./consent_page.html"))
  )

export default ui_consent_page
