import * as _ from "lodash";
import * as angular from "angular";

export function setSelectedAnswersFromSource(
  question: Taker.BaseQuestion,
  selectedAnswers: { id: string }[],
  sourceAnswers: { id: string }[],
  sourceSelectedAnswers: { id: string }[],
) {
  // get answer ids available based on source settings on the question
  let answer_ids = sourceSelectedAnswers?.map(a => a.id) || [];
  if (!question.config.answers_source_selected) {
    answer_ids = _.without(_.map(sourceAnswers, "id"), ..._.map(sourceSelectedAnswers, "id")) || [];
    if (!sourceSelectedAnswers || sourceSelectedAnswers.length == 0) answer_ids = [];
  }

  // remove any answers or explanations no longer available to select
  _.remove(selectedAnswers, a => !_.includes(answer_ids, a.id));
  if (question.explanations) {
    _.each(_.difference(Object.keys(question.explanations), answer_ids), a_id => delete question.explanations[a_id]);
  }

  //add a copy of any answer that have been checked on the source
  question.answers = [];
  sourceAnswers.forEach(answer => {
    if (_.includes(answer_ids, answer.id)) {
      const answer_copy = angular.copy(answer);
      question.answers.push(answer_copy);
    }
  });
}
