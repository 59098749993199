class TimeIntensityScale extends Taker.BaseQuestion
  @icon: "fa fa-long-arrow-right"
  @display_name: "Time Intensity Scale"
  @question_type: "time_intensity_scale"

  recordAnswer: (num, time) ->
    @selected_answers.push({score: num, time: time, answered_at: Date.now()})
    @validate() if @error_messages and @error_messages.length > 0
    @publishAnswerSelectedEvent()

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.TimeIntensityScale = TimeIntensityScale
