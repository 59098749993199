class RadioQuestion extends Taker.BaseQuestion
  @icon: "fa fa-dot-circle-o"
  @display_name: "Single Selection"
  @question_type: "radio"

  recordAnswer: (answer) ->
    super answer
    @current_answer = @selected_answers[0]

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data
    @selected_answers ||= []
    @current_answer = @selected_answers[0]

window.Taker.RadioQuestion = RadioQuestion
