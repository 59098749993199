import * as _ from "lodash";

class BasePageElement extends Taker.BaseElement {
  static initClass() {
    this.is_page_element = true;
  }

  constructor(data) {
    const init_data = {
      is_page_element: true,
    };
    _.merge(init_data, data);
    super(init_data);
  }
}
BasePageElement.initClass();

// @ts-ignore
window.Taker.BasePageElement = BasePageElement;

export default BasePageElement;
