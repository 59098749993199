import * as _ from "lodash";

const timedCtrl = function ($scope, $interval, languageHelper, surveyHelper, $uibModal, $timeout) {
  "ngInject";

  $scope.surveyHelper = surveyHelper;
  $scope.languageHelper = languageHelper;
  let time = null;
  let initial_time = null;
  let stop = null;

  function formatDisplayTime(seconds: number) {
    // display as HH:MM:SS
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours}:${minutes}:${secs}`;
  }

  $scope.formatDisplayTime = formatDisplayTime;
  $scope.startTimer = function (question) {
    $scope.started = true;

    if (!initial_time && !question.timer_complete) {
      time = $scope.display_time = parseFloat($scope.display_time);
      initial_time = question.config.initial_time || Date.now();
      question.config.initial_time = initial_time;
    }
    $scope.timer_running = true;

    return (stop = $interval(function () {
      const minus = (Date.now() - initial_time) / 1000;
      $scope.display_time = (time - minus).toFixed(0);

      const elapsed_time = Date.now() - initial_time;

      if (question.config.has_notifications && question.config.notifications.length > 0) {
        _.each(question.config.notifications, function (notification) {
          if (elapsed_time >= notification.start_seconds * 1000 && notification.notification_complete !== true) {
            const modal = $uibModal.open({
              templateUrl: "rj_shared/dialog.html",
              controller: "dialogCtrl",
              resolve: {
                settings() {
                  return {
                    title: "",
                    message: notification.text[surveyHelper.locale],
                    style: "warning",
                    hide_footer: true,
                  };
                },
              },
            });

            $timeout(() => modal.close(), notification.duration_seconds * 1000);

            return (notification.notification_complete = true);
          }
        });
      }

      if (elapsed_time >= question.config.time * 1000 && question.config.time_stop_method !== "manual") {
        $scope.stopTimer(question);
      }
    }, 100));
  };

  $scope.stopTimer = function (question) {
    $scope.recordTime(question);

    question.timer_complete = true;
    if (!question.selected_answers[0]) {
      question.recordAnswer(0, 0, question);
    } else {
      question.publishAnswerSelectedEvent();
    }
    $scope.timer_running = false;
    $scope.display_time = 0;

    $scope.unRegisterRequiredAction($scope.cid);
    $interval.cancel(stop);
    if (question.config.auto_advance) {
      $scope.nextPage();
    }
  };

  $scope.recordTime = function (question) {
    if (question.config.time_stop_method === "manual_timer") {
      return (question.time = Math.min(Date.now() - initial_time, question.config.time * 1000));
    } else if (question.config.time_stop_method === "timer") {
      return (question.time = question.config.time * 1000);
    } else {
      //manual or other record the actual time
      return (question.time = Date.now() - initial_time);
    }
  };

  $scope.recordAnswer = function (question, answer_id) {
    if ($scope.timer_running) {
      const click_time = Date.now() - initial_time;
      return question.recordAnswer(answer_id, click_time, question);
    } else {
      if ($scope.display_time > 0) {
        question.recordAnswer(answer_id, 0, question);
        return $scope.startTimer(question);
      }
    }
  };

  $scope.init = function (question) {
    $scope.cid = question.cid;
    if (surveyHelper.isBuilderPreview) {
      question.timer_complete = undefined;
      question.config.initial_time = undefined;
    }

    // clean this shit up
    if (!question.timer_complete) {
      $scope.registerRequiredAction($scope.cid);
      $scope.display_time = question.config.time || 30;
      let time_passed = 0;
      if (question.config.initial_time) {
        time_passed = Date.now() - question.config.initial_time;
      }
      $scope.display_time = Math.floor($scope.display_time - time_passed / 1000);
      if ($scope.display_time < 0) {
        $scope.display_time = 0;
      }
      if (question.config.auto_start && !surveyHelper.isBuilding) {
        return $scope.startTimer(question);
      }
    }
  };

  return ($scope.isTemporalCSSDisabled = function (question) {
    let manually_started = question.config.manual_start && !$scope.timer_running;

    if (question.config.can_have_opinion_question && question.config.has_opinion_question) {
      manually_started = question.config.manual_start && !$scope.timer_running && question.config.has_opinion_question;
    }

    return question.timer_complete || manually_started;
  });
};

export default timedCtrl;
