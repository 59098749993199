class TemporalDominance extends Taker.BaseQuestion
  @icon: "fa fa-trophy"
  @display_name: "Temporal Dominance"
  @question_type: "temporal_dominance"

  isSelected: (answer) ->
    if @selected_answers.length && answer && @selected_answers[@selected_answers.length - 1].id == answer.id
      return true
    else
      return false

  recordAnswer: (answer_id, time) ->
    new_answer = {id: answer_id, time: time, answered_at: Date.now()}
    @selected_answers.push(new_answer)
    @validate() if @error_messages and @error_messages.length > 0

    if this.config.has_opinion_question
      this.config.opinion_question.current_answer = new_answer
      this.config.opinion_question.selected_answer_id = null

    @publishAnswerSelectedEvent()

  showOpinionQuestion: (surveyHelper, languageHelper) ->
    if this.config.has_opinion_question
      if this.config.opinion_question.current_answer
        ans = _.find(@answers, {id: this.config.opinion_question.current_answer.id})
        this.config.opinion_question.current_text = "#{this.config.opinion_question.text[surveyHelper.locale]} (#{ans.text[surveyHelper.locale]})"
        this.config.show_opinion_question = true


  recordOpinionAnswer: (answer, radio_input) ->
    ans = _.find(@selected_answers, {id: this.config.opinion_question.current_answer.id, time: this.config.opinion_question.current_answer.time, answered_at: this.config.opinion_question.current_answer.answered_at})

    ans.opinion_answer =
      {
        id: answer.id
      }
    this.config.show_opinion_question = false
    this.config.opinion_question.current_answer = null
    radio_input.checked = false
    this.config.opinion_question.selected_answer_id = null

  constructor: (data) ->
    init_data =
      selected_answers: []
      config:
        opinion_question:
          orientation: 'horizontal'

    _.merge(init_data, data)
    super init_data

window.Taker.TemporalDominance = TemporalDominance
