import * as angular from "angular"


ui_completed_session = angular.module('redjade.survey_taker.ui.completed_session', [
])
  .controller('completedSessionCtrl', require("./completedSession.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/completed_session/completed_session.html', require("./completed_session.html"))
  )

export default ui_completed_session
