import * as _ from "lodash"
import * as angular from "angular"
import SeededShuffle from 'seededshuffle'
QuestionUtil = require("question_util.ts")

questionnaireTakerCtrl = (
  $scope
  $timeout
  $window
  $rootScope
  surveyHelper
  languageHelper
  $sce
) ->
  "ngInject"

  $scope.submitting = false
  $scope.surveyHelper = surveyHelper
  $scope.isEventPreview = $window.location.pathname.startsWith("/previewer/")
  $scope.languageHelper = languageHelper
  $scope.required_actions = []
  $scope.passed = true
  current_page_index = 0
  in_progress_questionnaire = {}
  pages_shuffled = false
  # elements = surveyHelper.elements

  $scope.getPreviousSamples = (cid) ->
    external_question = surveyHelper.getExternalQuestionByCID(cid)
    if external_question then external_question.previous_samples else []

  $scope.getReviewValue = (cid, sample = null) ->
    source = findQuestionByCID(cid)

    unless source
      external_question = surveyHelper.getExternalQuestionByCID(cid)
      external_answers = []
      if(sample && external_question)
        external_answers = _.find(external_question.submissions, (q) -> q.serving_sample?.code == sample.code)?.selected_answers
        unless _.any(external_answers)
          external_answers = _.flatten(_.map(external_question.submissions, "selected_answers"))

        answerToString(external_answers, external_question.submissions[0])

    else if source.has_been_seen && source.is_visible
      if $.isArray(source.selected_answers)
        answerToString(source.selected_answers, source)
      else
        answerToString(source.selected_answers[sample.id], source)

  answerToString = (answers, source) ->
    _.map(answers, (a) ->
      if(a.id)
        _.find(source.answers, (sa) -> sa.id == a.id).text[surveyHelper.locale]
      else
        if (a.score || a.score == 0) then a.score else a.text
    ).join(", ")

  ############################################################################################################
  #################################### methods to register/unregister question that ##########################
  #################################### cause the next button to be deactivated ###############################
  ############################################################################################################
  $scope.registerRequiredAction = (guid) ->
    $scope.required_actions.push(guid) unless _.includes $scope.required_actions, guid

  $scope.unRegisterRequiredAction = (guid) ->
    $scope.required_actions = _.without $scope.required_actions, guid

  $scope.unRegisterAllRequiredAction = ->
    $scope.required_actions = []
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################

  $scope.stopSurvey = () ->
    $scope.$emit "questionnaireStopEvent", { in_progress_questionnaire: surveyHelper.in_progress_questionnaire}
    return

  $scope.getCurrentPageIndex = ->
    return current_page_index

  $scope.previousPage = ->
    exit = false
    next = $scope.current_page
    loop
      current_page_index--
      if current_page_index < 0
        alert "begining of the questionnaire"
        exit = true
        current_page_index = 0
      else
        next = getPage(current_page_index)
      break unless !exit and (!isTriggerSatisfied(next) || !somethingIsVisible(next))
    $window.scrollTo(0, 0)
    $scope.unRegisterAllRequiredAction()
    $scope.current_page = next
    return

  $scope.nextPage = ->
    if $scope.required_actions.length == 0 && validatePageCompleteness($scope.current_page)
      ###### The Page is valid so lets check for terminates and go to exit ###########
      triggered_termination_scenario = evaluateTerminationScenarios()
      $scope.passed = false if triggered_termination_scenario
      ################################################################################

      ##### Add/Remove any demographics that are resulting from demographic assignments ######
      evaluateDemographics()
      #######################################################################################

      ###### We didn't find any terminates lets find the next page and evalute it ##########
      if $scope.current_page
        exit = false
        next = $scope.current_page

      loop
        current_page_index++
        if current_page_index >= in_progress_questionnaire.pages.length
          $scope.submitting = true
          #validate the entire questionnaire
          invalid_page_index = -1
          _.each in_progress_questionnaire.pages, (page, idx) ->
            if !validatePageCompleteness(idx)
              invalid_page_index = idx if invalid_page_index == -1
          if invalid_page_index == -1
            exit = true
            next = null

            RjEvent.emit('questionnaire-submit')
          else
            next = getPage(invalid_page_index)
        else
          next = getPage(current_page_index)
        break unless !exit and (!isTriggerSatisfied(next) || !somethingIsVisible(next))

      $window.scrollTo(0, 0)

      if next
        setPageStartTime(next)

      if $scope.passed || in_progress_questionnaire.config.terminate_after_completion
        $scope.current_page = next
      else
        $scope.current_page = {}

      if next && ($scope.passed || in_progress_questionnaire.config.terminate_after_completion)
        $scope.$emit "questionnaireNextPageEvent", { in_progress_questionnaire: surveyHelper.in_progress_questionnaire, serving_id: surveyHelper.serving_id, demographic_categories: surveyHelper.triggered_demographics, passed: $scope.passed }
      else
        $scope.$emit "questionnaireCompleteEvent", { in_progress_questionnaire: surveyHelper.in_progress_questionnaire, serving_id: surveyHelper.serving_id, demographic_categories: surveyHelper.triggered_demographics, passed: $scope.passed, termination_scenario: triggered_termination_scenario }

    return

  $scope.parentClass = (question) ->
    unless question.config.zipper
      "taker-question-block #{question.question_type}-wrapper"


  ############################################################################################################
  #################################### Event listeners #######################################################
  ############################################################################################################
  reloadTakerEvent = $scope.$on "reloadTakerEvent", (event, page_index) ->
    initInProgressQuestionnaire()
    evaluateDemographics()
    current_page_index = 0
    if surveyHelper.isBuilderPreview
      current_page_index = page_index
    else if surveyHelper.allow_serving_retake
      current_page_index = 0
    else
      current_page_index = findPageToReloadTo(in_progress_questionnaire.pages)
    $scope.current_page = getPage(current_page_index)  # TODO: rethink this
    setPageStartTime($scope.current_page)
    $scope.submitting = false
    $scope.passed = true


  #this re-evaluates the page to show any questioned that may have been triggered by the answer selection
  answerSelectedEvent = $scope.$on "answerSelectedEvent", (event, question) ->
    $scope.$evalAsync ->
      surveyHelper.answerSelected(question)
      $scope.current_page = evaluatePage $scope.current_page


  $scope.$on '$destroy', ->
    reloadTakerEvent()
    answerSelectedEvent()
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################





  ############################################################################################################
  ###################### method to evaluate if a single trigger meets its condition ##########################
  ############################################################################################################
  logicThing = (trigger, userValues) ->
    question = findQuestionByCID(trigger.parent_cid, trigger.parent_sample_id)
    if question?.question_type == 'numeric_grid'
      condition = trigger.condition
      triggerValues = parseInt(trigger.target_score) if trigger.target_score
      if condition is "equal"
        res = (parseFloat(userValues?.score) == parseFloat(triggerValues))
      else if condition is "greater than"
        res = (parseFloat(userValues?.score) > parseFloat(triggerValues))
      else if condition is "less than"
        res = (parseFloat(userValues?.score) < parseFloat(triggerValues))
    else
      condition = trigger.condition
      triggerValues = trigger.answers
      if trigger.type is "question" && userValues[0]
        if userValues[0].id
          userValues = _.map(userValues, 'id')
        else if question?.question_type == 'preference'
          userValues = _.map(userValues, 'sample_id')
      if condition is "equal"
        if userValues[0]?.hasOwnProperty('score')
          res = (parseFloat(userValues[0]?.score) == parseFloat(triggerValues[0]))
        else
          res = (_.difference(triggerValues, userValues).length is 0) and (_.difference(userValues, triggerValues).length is 0)
      else if condition is "not equal"
        res = (_.difference(triggerValues, userValues).length > 0) or (_.difference(userValues, triggerValues).length > 0)
      else if condition is "any"
        res = _.intersection(triggerValues, userValues).length > 0
      else if condition is "not any"
        res = _.intersection(triggerValues, userValues).length is 0
      else if condition is "all"
        inter = _.intersection(triggerValues, userValues)
        res = (_.difference(triggerValues, inter).length is 0)
      else if condition is "not all"
        inter = _.intersection(triggerValues, userValues)
        res = (_.difference(triggerValues, inter).length > 0)
      else if condition is "greater than"
        res = (parseFloat(userValues[0]?.score) > parseFloat(triggerValues[0]))
      else if condition is "less than"
        res = (parseFloat(userValues[0]?.score) < parseFloat(triggerValues[0]))
      res


  ############################################################################################################
  ############################################################################################################
  ############################################################################################################




  ############################################################################################################
  #################################### Init the questionnaire and taker ####################################
  ############################################################################################################
  initInProgressQuestionnaire = ->
    my_questionnaire_copy = {}
    if surveyHelper.isBuilderPreview
      $scope.unRegisterAllRequiredAction()
      surveyHelper.started_at = Date.now()
      if surveyHelper.questionnaire.page_groups
        surveyHelper.in_progress_questionnaire = my_questionnaire_copy = angular.copy(surveyHelper.questionnaire.page_groups[0])
        my_questionnaire_copy.config = angular.copy(surveyHelper.questionnaire.config)
    else
      if surveyHelper.questionnaire.pages
        my_questionnaire_copy = angular.copy(surveyHelper.questionnaire)
    surveyHelper.in_progress_questionnaire = in_progress_questionnaire = deserialize my_questionnaire_copy
    randomizePages(surveyHelper.in_progress_questionnaire)

    surveyHelper.addStyles(in_progress_questionnaire.config) if in_progress_questionnaire.config
    deserializeExternalQuestions()
    surveyHelper.unzipQuestions(in_progress_questionnaire.pages)
    return
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################



  ############################################################################################################
  #################################### Find question in questionnaire by cid #################################
  ############################################################################################################
  findQuestionByCID = (cid, sample_id) ->
    surveyHelper.findQuestionByCID(cid, sample_id)
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################


  ############################################################################################################
  #################################### Get the select answer from a question by cid ##########################
  ############################################################################################################
  # NOTE: questions with source answers intentionally not randomized
  setSelectedAnswersFromSource = (question) ->
    source_cid = question.config.answers_source
    # if the answer source calls for a specific sample, provide those answers, otherwise zipper -> zipper
    sample_id = question.config.answers_source_sample_id || question.sample_id
    source_question = findQuestionByCID(source_cid, sample_id)
    return unless source_question

    question.config.media = source_question.config.media if source_question.config.media

    QuestionUtil.setSelectedAnswersFromSource(
      question,
      question.selected_answers,
      source_question.answers,
      source_question.selected_answers
    )

    # add the answer text from the source answer
    _.each(question.answers, (answer) ->
      source_answer = _.find(source_question.answers, (a) -> a.id == answer.id)
      if (answer.config.explanation && question.config.answers_source_selected)
        answer.config.explanation = false
        explain_text = _.find(source_question.selected_answers, (a) -> a.id == answer.id).text
        answer.text[surveyHelper.locale] = source_answer.text[surveyHelper.locale]
        answer.text[surveyHelper.locale] += " (#{explain_text})" if explain_text
    )

  ############################################################################################################
  ############################################################################################################
  ############################################################################################################


  ############################################################################################################
  #################################### Look for a visible element ################# ##########################
  ############################################################################################################
  somethingIsVisible = (page) ->
    visible_element = _.find page.questions, (q) ->
      (q.config.zipper && _.any(q.unzipped_questions, (uq) -> uq.is_visible)) ||
      (!q.config.zipper && q.is_visible)
    return visible_element != undefined
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################


  ############################################################################################################
  #################################### Look for a visible element ################# ##########################
  ############################################################################################################
  allTriggerElementsHaveBeenSeen = (demographic_assignment) ->
    _.all demographic_assignment.triggers, (trigger) ->
      q = findQuestionByCID(trigger.parent_cid, trigger.parent_sample_id) if trigger.type == 'question'
      return !q || q.has_been_seen
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################



  ############################################################################################################
  #################################### Are the triggers for the page or question satisfied ###################
  #################################### causing the element to be visible ####################################
  ############################################################################################################
  isTriggerSatisfied = (element) ->
    return unless element
    element["triggers_condition"] = "all" unless element["triggers_condition"]
    res = undefined

    if element.question_type == "discrim:anota"
      # auto hide anota questions if the current sample is the reference
      return element.config.reference_sample_id != surveyHelper.samples?[0]?.id

    if !element.triggers || element.triggers.length < 1
      return true
    else
      res = true
      _.each element.triggers, (trigger) ->
        if trigger.type is "question"
          parent = findQuestionByCID(trigger.parent_cid)
          if parent && parent.has_been_seen && parent.is_visible
            if parent.isDiscrim()
              if trigger.condition == "correct"
                res = (parent.isCorrect() == true)
              else
                res = (parent.isCorrect() == false) #must be explicitly checked because this is null until answered.
            else if parent.selected_answers && _.any(parent.selected_answers)
              sample_id = trigger.parent_sample_id || element.sample_id
              userValues = if sample_id && !Array.isArray(parent.selected_answers) then parent.selected_answers[sample_id] else parent.selected_answers
              if _.any(userValues)
                res = logicThing(trigger, userValues)
              else
                res = false
            else if parent.groups && parent.groups.length > 0
              # if numeric grid, have to filter out the 'selected answers' from what the trigger wants to check
              if parent.question_type == 'numeric_grid'
                logicThings = []
                trigger_groups = parent.groups
                _.each trigger_groups, (group) ->
                  # group.selected_answers = _.reject(group.selected_answers, (answer) -> !_.includes(trigger.answers, answer.id))
                  selected_answers_copy = _.reject(group.selected_answers, (answer) -> !_.includes(trigger.answers, answer.id))
                  # need to wait until selected answers matches the trigger answers length
                  if selected_answers_copy.length >= trigger.answers.length
                    _.each selected_answers_copy, (answer) ->
                      logicThings.push(logicThing(trigger, answer))
                  else
                    res = false
              else
                trigger_groups = _.select(parent.groups, (group) -> _.includes(trigger.groups, group.id ))
                logicThings = _.map(trigger_groups, (group) -> (return logicThing(trigger, group.selected_answers) if group.selected_answers ))

              if trigger.group_condition == "all" && logicThings.length > 0
                res = _.all(logicThings)
              else
                res = _.any(logicThings)
            else
              res = false
          else
            res = false

        else if trigger.type is "demographic"
          demos = surveyHelper.triggered_demographics[trigger.parent_cid] || []
          res = logicThing(trigger, demos)
        else if trigger.type is "sample"
          res = logicThing(trigger, _.map(surveyHelper.samples, "id"))
        else if trigger.type is "position"
          # res = logicThing(trigger, surveyHelper.day, surveyHelper.position)
          res = logicThing(trigger, [surveyHelper.position])
        else if trigger.type is "day"
          res = logicThing(trigger, [surveyHelper.day])

        if element.triggers_condition is "any"
          if res
            res = true
            false
        else if element.triggers_condition is "all"
          unless res
            res = false
            false

      res
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################


  ############################################################################################################
  #################################### Position filter Logic #################################################
  ############################################################################################################
  ############################################################################################################
  positionLogicThing = (trigger, current_day, current_position) ->
    condition = trigger.condition
    triggerValues = trigger.answers
    if condition is "any"
      return ((parseInt(trigger.parent_cid) == current_day) && _.includes(triggerValues, current_position))

  ############################################################################################################
  #################################### Check if the page has passed all validation ###########################
  ############################################################################################################
  validatePageCompleteness = (page) ->
    if page is undefined
      return true
    self = this
    all_questions_are_valid = true
    _.each page.questions, (question) ->

      if surveyHelper.samples
        sample_length = surveyHelper.samples.length
      else
        sample_length = 0

      if (question.config.zipper)
        all_questions_are_valid &= question.zipperValidate(sample_length) unless (question.zipperValidate == undefined)
      else if (question.config.zipper_merge)
        all_questions_are_valid &= question.zipperMergeValidate(sample_length) unless (question.zipperMergeValidate == undefined)
      else #normal question
        all_questions_are_valid &= question.validate(sample_length, surveyHelper.locale) unless (question.validate == undefined)
      return
    if !all_questions_are_valid
      $timeout () ->
        errorElements = document.querySelectorAll('.error-message')
        if errorElements.length > 0
          errorElements[0].scrollIntoView({block: "center", inline: "start", behavior: "smooth"})
      , 0
    return all_questions_are_valid
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################


  ############################################################################################################
  # evaluate each page and question to determine if it should always be shown or if a trigger has caused it to be shown
  ############################################################################################################
  evaluatePage = (page) ->
    if isTriggerSatisfied(page)
      page.is_visible = true
      triggerCheck = (question) ->
        if isTriggerSatisfied(question)
          question.is_visible = true
        else
          question.is_visible = false
        return
      _.each(page.questions, (question) ->
        question.selected_answers = []  unless question.selected_answers
        if question.config.zipper
          question.is_visible = true
          _.each(question.unzipped_questions, (unzipped) ->
            setSelectedAnswersFromSource(unzipped)  if unzipped.config.answers_source  if unzipped
            triggerCheck(unzipped)
            unzipped.selected_answers = [] unless unzipped.selected_answers
            return
          )
        else
          setSelectedAnswersFromSource(question)  if question.config.answers_source  if question
          triggerCheck(question)
        return
      )
    else if page
      page.is_visible = false
      _.each page.questions, (question) ->
        question.is_visible = false

    page
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################


  ############################################################################################################
  # evaluate each demographic assignment to see what should be added/removed
  ############################################################################################################
  evaluateDemographics = ->
    # this is an additive only approach
    # temp = _.extend({}, surveyHelper.user_demographics)
    # _.each in_progress_questionnaire.demographic_assignments, (demographic_assignment) ->
    #   if isTriggerSatisfied(demographic_assignment)
    #     category = _.find(surveyHelper.demographic_categories, (dc) -> dc.id == demographic_assignment.demographic_category_id)
    #     if category && category.exclusive
    #       temp[demographic_assignment.demographic_category_id] = [demographic_assignment.demographic_id]
    #     else if category
    #       temp[demographic_assignment.demographic_category_id] = [] unless temp[demographic_assignment.demographic_category_id]
    #       temp[demographic_assignment.demographic_category_id].push(demographic_assignment.demographic_id) unless _.includes temp[demographic_assignment.demographic_category_id], demographic_assignment.demographic_id
    # surveyHelper.triggered_demographics = temp


    _.each in_progress_questionnaire.demographic_assignments, (demographic_assignment) ->
      surveyHelper.triggered_demographics[demographic_assignment.demographic_category_id] = surveyHelper.triggered_demographics[demographic_assignment.demographic_category_id] || []
      if isTriggerSatisfied(demographic_assignment)
        category = _.find(surveyHelper.demographic_categories, (dc) -> dc.id == demographic_assignment.demographic_category_id)
        if category && category.exclusive
          surveyHelper.triggered_demographics[demographic_assignment.demographic_category_id] = [demographic_assignment.demographic_id]
        else
          surveyHelper.triggered_demographics[demographic_assignment.demographic_category_id].push(demographic_assignment.demographic_id) unless _.includes surveyHelper.triggered_demographics[demographic_assignment.demographic_category_id], demographic_assignment.demographic_id
      else
        if allTriggerElementsHaveBeenSeen(demographic_assignment)
          surveyHelper.triggered_demographics[demographic_assignment.demographic_category_id] = _.without surveyHelper.triggered_demographics[demographic_assignment.demographic_category_id], demographic_assignment.demographic_id

  ############################################################################################################
  ############################################################################################################
  ############################################################################################################



  ############################################################################################################
  # evaluate each termination scenario to see if a participant is terminated from screener
  ############################################################################################################
  evaluateTerminationScenarios = ->
    found_a_terminate = null
    _.each in_progress_questionnaire.termination_scenarios, (scenario) ->
      unless found_a_terminate
        if isTriggerSatisfied(scenario)
          found_a_terminate = scenario
    return found_a_terminate


  ############################################################################################################
  ############################################################################################################
  ############################################################################################################


  ############################################################################################################
  #################################### Evaluate and process the page by index ################################
  ############################################################################################################
  setPageStartTime = (page) ->
    if !page.start_time
      page.start_time = Date.now()
      $scope.$emit "pageStartedEvent", { start_time: page.start_time, page_id: page.id, in_progress_questionnaire_id: surveyHelper.in_progress_questionnaire_id}
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################



  ############################################################################################################
  #################################### Convert the plain json to rich models #################################
  ############################################################################################################
  deserialize = (data) ->
    _.each data.pages, (page) ->
      questions = []
      _.each page.questions, (question) ->
        clazz = surveyHelper.findClazz(question.question_type)
        clazzy_question = new clazz(question)
        questions.push(clazzy_question)
      page.questions = questions
    return data
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################


  deserializeExternalQuestions = ->
    return if _.any(surveyHelper.external_questions, (eq) -> eq.submissions[0] && !eq.submissions[0].data)
    _.each surveyHelper.external_questions, (data) ->
      temp = []
      _.each data.submissions, (sub) ->
        clazz = _.find surveyHelper.elements, (element) -> element.question_type == sub.data.question_type
        q = new clazz(sub.data)
        q.serving_sample = sub.serving_samples?[0]
        q.sample_id = sub.sample_ids[0]
        temp.push(q)

      data.submissions = temp
      return
    return

  randomizePages = (in_progress_questionnaire) ->
    # not_fixed_pages = _.reject in_progress_questionnaire.pages, (page) ->
    #   return _.includes fixed_question_types, question.question_type
    not_fixed_pages = in_progress_questionnaire.pages

    # not_fixed_pages = SeededShuffle.shuffle(not_fixed_pages, surveyHelper.serving_id) if page.config.randomize_questions == 'randomize_on_sample_id'
    switch surveyHelper.randomize_pages
      when 'randomize_on_participant'
        not_fixed_pages = SeededShuffle.shuffle(not_fixed_pages, surveyHelper.participant_id)

    # fixed = _.map page.questions, (question, idx) ->
    #   unless _.includes not_fixed_questions, question
    #     return {index : idx, question: question}
    # _.each fixed, (item) ->
    #   not_fixed_questions.splice(item.index, 0, item.question) if item

    _.each not_fixed_pages, (page, idx) ->
      _.each page.questions, (question) ->
        question.page_sequence_position = idx
        return
      return

    in_progress_questionnaire.pages = not_fixed_pages
    pages_shuffled = true # dont shuffle twice

  ############################################################################################################
  #################################### Shuffle the questions if configed ###############################
  ############################################################################################################
  randomizeQuestions = (page) ->
    shuffle_type = 'none'
    if !page.config.randomize_questions || page.config.randomize_questions == 'none'
      shuffle_type = 'none'
    else if page.config.randomize_questions == true || page.config.randomize_questions == 'unseeded'
      shuffle_type = 'unseeded'
    else
      shuffle_type = 'seeded'

    if shuffle_type != 'none'
      fixed_question_types = ['media', 'page_text', 'review_table', 'timer', 'questionnaire_timer']
      not_fixed_questions = _.reject page.questions, (question) ->
        return _.includes fixed_question_types, question.question_type

      not_fixed_questions = SeededShuffle.shuffle(not_fixed_questions, Math.random()) if page.config.randomize_questions == 'unseeded'
      not_fixed_questions = SeededShuffle.shuffle(not_fixed_questions, surveyHelper.participant_id) if page.config.randomize_questions == 'seeded'

      fixed = _.map page.questions, (question, idx) ->
        unless _.includes not_fixed_questions, question
          return {index : idx, question: question}
      _.each fixed, (item) ->
        not_fixed_questions.splice(item.index, 0, item.question) if item
        return


      page.questions = not_fixed_questions
      page.shuffled = true # dont shuffle twice

    _.each page.questions, (question, idx) ->
      question.question_sequence_position = idx if question
      return

    page
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################

  ############################################################################################################
  #################################### Shuffle the answer/subjects if configed ###############################
  ############################################################################################################
  randomizeQuestionAnswers = (question) ->
    shuffle_type = 'none'
    if !question.config.randomize || question.config.randomize == 'none'
      shuffle_type = 'none'
    else if question.config.randomize == true || question.config.randomize == 'unseeded'
      shuffle_type = 'unseeded'
    else
      shuffle_type = 'seeded'

    if shuffle_type != 'none'
      not_fixed_answers = _.reject question.answers, (answer) ->
        return answer.config && answer.config.fixed_position
      not_fixed_answers = SeededShuffle.shuffle(not_fixed_answers, surveyHelper.participant_id) if shuffle_type == 'seeded'
      not_fixed_answers = SeededShuffle.shuffle(not_fixed_answers, Math.random()) if shuffle_type == 'unseeded'

      # put the fixed answers back in the correct place
      fixed = _.map question.answers, (answer, idx) ->
        if answer.config && answer.config.fixed_position
          return {index : idx, answer: answer}
      _.each fixed, (item) ->
        not_fixed_answers.splice(item.index, 0, item.answer) if item
      question.answers = not_fixed_answers



    # handling of in progress questions that were missed in the merge, old grids
    # used 'randomize_subjects' instead of new 'randomized_groups'
    if !question.config.randomize_groups
      if !question.config.randomize_subjects
        question.config.randomize_groups = 'none'
      else
        question.config.randomize_groups = 'unseeded'

    if question.config.randomize_groups == 'unseeded'
      question.groups = SeededShuffle.shuffle(question.groups, Math.random())
    else if question.config.randomize_groups == 'seeded'
      question.groups = SeededShuffle.shuffle(question.groups, surveyHelper.participant_id)

    question.shuffled = true # dont shuffle twice

  randomizeAnswers = (page) ->
    _.each(page.questions, (question) ->
      if !question.shuffled
        if question.config.zipper
          _.each(question.unzipped_questions, (q) ->
            randomizeQuestionAnswers(q)
            return
          )
          question.shuffled = true
        else
          randomizeQuestionAnswers(question)
      return
    )
    page
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################


  ############################################################################################################
  #################################### Evaluate and process the page by index ################################
  ############################################################################################################
  getPage = (index) ->
    evaluatePage randomizeQuestions randomizeAnswers in_progress_questionnaire.pages[index]
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################


  ############################################################################################################
  #TODO: High Priority clean up
  #This is only used to determine which page I should go to if a reload occurs.
  #evalute the page and determine whether it should be shown or skipped when loading or reloading taker
  ############################################################################################################
  findPageToReloadTo = (pages) ->
    return_index = null
    _.each pages, (page, idx) ->
      evaluatePage(page)
      has_been_seen = null
      if (isTriggerSatisfied(page) && somethingIsVisible(page) && return_index == null) #(page.is_visible && somethingIsVisible(page))
        has_unanswerd_question = false
        #do we find atleast one question that is satisfied but isn't valid
        has_been_seen = _.find page.questions, {has_been_seen: true}
        if has_been_seen
          _.each page.questions, (question) ->
            question_needed = question["is_visible"] &&
              !(
                (question.config.zipper && question.zipperValidate(surveyHelper?.samples?.length || 0)) ||
                (!question.config.zipper && question.validate(surveyHelper?.samples?.length || 0, surveyHelper.locale))
              )

            if question_needed
              question.error_messages = []
              return_index = idx if return_index == null
        else
          return_index = idx if return_index == null
      #else
      #  return false

    return if return_index != null then return_index else pages.length-1
  ############################################################################################################
  ############################################################################################################
  ############################################################################################################

export default questionnaireTakerCtrl
