class TextHighlightQuestion extends Taker.BaseQuestion
  @icon: "fa fa-dot-circle-o"
  @display_name: "Text Highlight"
  @question_type: "text_highlight"

  modeChanged: (mode_id) ->
    for mode in @groups
      mode.config.active = mode.id == mode_id

  groupStyle: (group_id) ->
    for mode in [...@groups].sort((a, b) ->  if b.config.active then 1 else -1 )
      if mode.selected_answers.find((g) -> g.id == group_id)
        return { 'color': this.invertColor(mode.config.color), 'background-color': mode.config.color }
    null

  modeTotalSelected: (mode, locale) ->
    mode.selected_answers.filter((g) -> g.locale == locale).length

  groupClicked: (group, locale, selected_via_drag = false) ->
    return if (selected_via_drag && !this.hover_selecting) ||
      group.config.disabled ||
      group.text[locale] == '<br>'

    mode = _.find(@groups, (mode) -> mode.config.active)
    active_mode_answers = mode.selected_answers
    found_index = active_mode_answers.findIndex((g) -> g.id == group.id)
    this.hover_selecting = true unless selected_via_drag
    if !this.mouse_listeners_added && !selected_via_drag
      document.addEventListener('mouseup', ((event) -> this.hover_selecting = false).bind(this))
      this.mouse_listeners_added = true

    if found_index != -1
      if selected_via_drag
        return unless this.hover_mode == 'deselect'
      else
        this.hover_mode = 'deselect'
      active_mode_answers.splice(found_index, 1) if found_index != -1
    else
      if selected_via_drag
        return unless this.hover_mode == 'select'
      else
        this.hover_mode = 'select'

      can_select = false
      question_max_select = parseInt(_.find(@validations, (v) -> v.type == 'selection count')?.max)
      will_replace = !@config.group_multiselect_allowed && _.map(@groups, (map_g) -> !map_g.config.active && map_g.selected_answers.findIndex((g) -> g.id == group.id) != -1).indexOf(true) != -1
      if (@config.group_multiselect_allowed || !will_replace || (!@config.group_multiselect_allowed && will_replace))
        can_select = true

      if !@config.group_multiselect_allowed && can_select
        for group_remove in @groups
          found_index = group_remove.selected_answers.findIndex((g) -> g.id == group.id && g.locale == locale)
          group_remove.selected_answers.splice(found_index, 1) if found_index != -1

      if can_select
        active_mode_answers.push({
          id: group.id
          locale: Object.keys(group.text)[0]
          answered_at: Date.now()
        })

    clearTimeout(this.publish_timeout) if this.publish_timeout
    this.publish_timeout = setTimeout(@publishAnswerSelectedEvent.bind(this), 700)

  # - couldn't find any kind of premade color invert that was compatible with IE
  # - we use the builder question class for taker html display in the builder,
  #   so any calculated styling has to happen in both
  invertColor: (hex) ->
    if hex.indexOf('#') == 0
      hex = hex.slice(1)
    if hex.length == 3
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    if hex.length != 6
      throw new Error('Invalid HEX color.')
    r = parseInt(hex.slice(0, 2), 16)
    g = parseInt(hex.slice(2, 4), 16)
    b = parseInt(hex.slice(4, 6), 16)
    return if (r * 0.299 + g * 0.587 + b * 0.114) > 186 then '#000000' else '#FFFFFF'

  totalSelected: (locale) ->
    _.sum(@groups.map((map_g) -> map_g.selected_answers.filter((g) -> g.locale == locale).length))

  validate: (_sample_length, locale) ->
    @error_messages = []
    if @is_visible
      _.each @validations, (rule) =>
        if rule.type is "required" && this.totalSelected(locale) == 0
          @error_messages.push {type: "required"}

        if rule.type is "selection count" && @error_messages.length == 0
          if rule.min && this.totalSelected(locale) < rule.min
            @error_messages.push {type: "min_selections", value: "#{rule.min}"}
          if rule.max && this.totalSelected(locale) > rule.max
            @error_messages.push {type: "max_selections", value: "#{rule.max}"}

      if @error_messages.length == 0
        _.each @groups, (group) =>
          total_selected = group.selected_answers.filter((g) -> g.locale == locale).length
          if group.config.min_select && total_selected < group.config.min_select
            @error_messages.push {type: "min_selections_category", value: "#{group.config.min_select}", category: group.text[locale]}

          if group.config.max_select && total_selected > group.config.max_select
            @error_messages.push {type: "max_selections_category", value: "#{group.config.max_select}", category: group.text[locale]}

    @error_messages.length is 0

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)

    init_data.hover_selecting = false
    init_data.mouse_listeners_added = false

    super init_data

window.Taker.TextHighlightQuestion = TextHighlightQuestion
