import * as _ from "lodash"
// do clone deep from lodash
import { cloneDeep } from "lodash"

import { IQuestionnaire } from "~angular/src/apps/analyses/ui/analysis_topline/question-builder"

const surveyElements = [
  Taker.RadioQuestion,
  Taker.TextHighlightQuestion,
  Taker.CheckboxQuestion,
  Taker.TextQuestion,
  Taker.NumericQuestion,
  Taker.RangeSliderQuestion,
  Taker.RangeTargetQuestion,
  Taker.DropdownQuestion,
  Taker.SingleResponseGridQuestion,
  Taker.MultipleResponseGridQuestion,
  Taker.SymantecDifferentialScaleQuestion,
  Taker.NumericGridQuestion,
  Taker.RankingQuestion,
  Taker.PreferenceQuestion,
  Taker.TimeIntensityScale,
  Taker.TemporalCata,
  Taker.TemporalDominance,
  Taker.DuoTrio,
  Taker.Triangle,
  Taker.PairedComparison,
  Taker.ANotA,
  Taker.Tetrad,
  Taker.TwoOfFive,
  Taker.Hexad,
  Taker.Nafc,
  Taker.PageText,
  Taker.ReviewTable,
  Taker.Media,
  Taker.QuestionnaireTimer,
  Taker.Association,
  Taker.DemographicQuestion,
  Taker.NappingQuestion,
  Taker.ConjointQuestion,
  Taker.MaxDiffQuestion,
  Taker.MediaUploadQuestion,
  Taker.FreeSortQuestion,
  Taker.SensoryWheelQuestion,
]

export const surveyHelperModule = {
  started_at: null,
  is_first_sample: true,
  locale: "en_US",
  allow_serving_retake: false,
  enforce_serving_order: true,
  active_page_group_index: 0,
  demographic_categories: [] as any[],
  questionnaire: {} as IQuestionnaire,
  full_questionnaire: null as IQuestionnaire,
  participant_queue_number: 1,
  in_progress_questionnaire: {} as Taker.InProgressQuestionnaire,
  user_demographics: {},
  triggered_demographics: {},
  samples: [],
  external_questions: [],
  serving_id: -1,
  participant_id: -1,
  day: null,
  isBuilding: false,
  isBuilderPreview: false,
  position: null,
  in_progress_questionnaire_id: null,
  randomize_pages: null,
  elements: [] as Function[],
  populateFromServer(data) {
    this.in_progress_questionnaire_id = data.questionnaire.id
    this.started_at = data.questionnaire.started_at ? new Date(data.questionnaire.started_at) : Date.now()
    this.user_demographics = data.user_demographics || {}
    this.setLocale(data.locale)
    this.allow_serving_retake = data.allow_serving_retake
    this.enforce_serving_order = data.enforce_serving_order
    this.samples = data.samples
    this.external_questions = data.external_questions
    this.questionnaire = data.questionnaire.data
    this.questionnaire.questionnaire_type = data.questionnaire.questionnaire_type
    this.questionnaire.sample_entry_display = data.questionnaire.sample_entry_display
    this.demographic_categories = data.demographic_categories
    this.serving_id = data.serving_id
    this.participant_id = data.participant_id // Math.floor(Math.random() * 10 + 1)
    this.day = data.day
    this.position = data.position
    this.triggered_demographics = _.extend({}, this.user_demographics)
    this.isBuilding = false
    this.isPreview = false
    this.randomize_pages = data.randomize_pages
    return getInjector("variableCache").load(data.variables)
  },
  reset() {
    this.isPreview = false
    this.is_first_sample = true
    this.setLocale("en_US")
    this.active_page_group_index = 0
    this.questionnaire = {}
    this.participant_queue_number = 1
    this.user_demographics = {}
    const triggered_demographics = {}
    this.samples = []
    this.external_questions = []
    return (this.demographic_categories = [])
  },
  findClazz(question_type) {
    return _.find(this.elements, element => element.question_type === question_type)
  },
  setLocale(locale: string) {
    this.locale = locale
    // @ts-ignore
    if (window.RjEvent) {
      RjEvent.emit("locale-changed", this.locale)
    }
  },
  isVideo(url) {
    const suffix = "mp4"
    if (!url) {
      return false
    } else {
      return url.toLowerCase().indexOf(".mp4") > -1
    }
  },
  isEnglish() {
    return this.locale === "en_US" || this.locale === "en_AU" || this.locale === "en_GB"
  },
  duoTrioSamples() {
    return _.filter(this.samples, (item, idx) => idx !== 0)
  },
  rankingSamples(question) {
    return _.filter(this.samples, (item, idx) => !_.includes(question.selected_answers, item.id))
  },
  rankingVisibility(question, sample) {
    const f = _.filter(question.selected_answers, {
      serving_sample_id: sample.serving_sample_id,
    })
    if (f.length > 0) {
      return "hidden"
    } else {
      return "visible"
    }
  },
  getRange(question) {
    const min = parseFloat(question.config.min) || 0
    const max = parseFloat(question.config.max) || 10
    const step = parseFloat(question.config.step) || 1
    return _.range(min, max + 1, step)
  },
  getSampleCodeForServing(serving_sample_id) {
    const sample = _.find(this.samples, s => serving_sample_id === s.serving_sample_id)
    if (sample) {
      return sample.code
    } else {
      return ""
    }
  },
  getLabelsByPlacement(labels, placement) {
    return _.filter(labels, label => label.placement === placement)
  },
  demoCatById(id) {
    return _.find(this.demographic_categories, {
      id,
    })
  },
  getExternalQuestionByCID(cid) {
    return _.find(this.external_questions, {
      cid,
    })
  },
  findInProgressPageById(id) {
    return _.find(this.in_progress_questionnaire.pages, {
      id,
    })
  },
  activePage() {
    return this.questionnaire.page_groups[0].pages[this.active_page_group_index]
  },
  insertRule(stylesheet, selector, rule) {
    if (stylesheet.insertRule) {
      return stylesheet.insertRule(selector + rule, stylesheet.cssRules.length)
    } else if (stylesheet.addRule) {
      return stylesheet.addRule(selector, rule, -1)
    }
  },
  addStyles(config) {
    let rule, selector
    const stylesheet = document.styleSheets[0]
    if (config.question_font_size != null) {
      selector = "h4[variable-replacer]"
      rule = "{font-size:" + config.question_font_size + "px}"
      this.insertRule(stylesheet, selector, rule)
    }
    if (config.response_font_size != null) {
      selector = ".question label, .question .answer, .question .slider-label"
      rule = "{font-size:" + config.response_font_size + "px}"
      this.insertRule(stylesheet, selector, rule)
    }
    if (config.question_spacing != null) {
      selector = ".questionnaire-taker .taker-question-block"
      rule = "{margin-bottom:" + config.question_spacing + "px !important}"
      return this.insertRule(stylesheet, selector, rule)
    }
  },
  answerSelected(question) {
    question["has_been_seen"] = true // zipper questions are such a mess but mark the unzipped question as seen
    if (question.isDiscrim && question.isDiscrim()) {
      question.determineCorrectness(this)
    }
    if (question.config.has_opinion_question) {
      question.showOpinionQuestion(this, getInjector("languageHelper"))
    }
  },
  findQuestionByCID(cid: string, sample_id?: string): Taker.BaseQuestion {
    let question = null
    _.each(this.in_progress_questionnaire.pages, function (page) {
      _.each(page.questions, function (q) {
        if (q.cid === cid) {
          if (q.config.zipper && sample_id) {
            question = _.find(q.unzipped_questions, unzip => unzip.sample_id === sample_id)
            if (!question) {
              return null
            }
            // we obfuscate the unzipped question cids and pretend tog the outside world like they are the parent with a sample id
            // we still need the cids simply because the templates/question controllers dont understand sample context
            question = cloneDeep(question)
            question.cid = question.zipper_cid
            question.answers = _.map(question.answers, function (ans) {
              ans.id = ans.zipper_answer_id
              return ans
            })
            question.selected_answers = _.map(question.selected_answers, function (ans) {
              ans.id = ans.zipper_answer_id
              return ans
            })
          } else {
            question = q
          }
          return false
        }
      })
      if (question) {
        return false
      }
    })
    return question
  },
  unzipQuestions(pages) {
    _.each(pages, page => {
      const zipper_questions = _.select(page.questions, q => q.config.zipper)
      _.each(zipper_questions, zipper_question => {
        zipper_question.unzipped_questions = []
        _.each(this.samples, sample => {
          const question = cloneDeep(zipper_question)
          delete question.unzipped_questions
          question.cid = guid()
          question.is_visible = true
          question.config.zipper = false
          question.zipper_cid = zipper_question.cid
          question.sample_id = sample.id
          //replace {{sample_name}} with {{sample_name[id]}} so that the variable is scoped to the unzipped sample}}
          try {
            if (!this.isBuilding && !this.isBuilderPreview) {
              _.each(question.text, function (value, key) {
                question.text[key] = value.replace(/(?:{{|#)sample_(\w+)(?:}}|#)/g, `{{sample_$1[${sample.id}]}}`)
                return _.each(
                  question.answers,
                  answer =>
                    (answer.text[key] = __guard__(answer != null ? answer.text[key] : undefined, x =>
                      x.replace(/(?:{{|#)sample_(\w+)(?:}}|#)/g, `{{sample_$1[${sample.id}]}}`),
                    )),
                )
              })
            }
          } catch (error) {
            //console.log(`${error}`);
          }
          _.each(question.answers, ans => {
            ans.zipper_answer_id = ans.id
            ans.id = guid()
            ans.sample_id = sample.id
          })
          if (this.isBuilderPreview) {
            question.selected_answers = []
            question.has_been_seen = false
            delete question.text[sample.id]
          } else {
            if (zipper_question.selected_answers) {
              question.selected_answers = _.map(
                zipper_question.selected_answers[sample.id],
                zipper_selected_answers => {
                  const zsa = cloneDeep(zipper_selected_answers)
                  if (zsa.id) {
                    const unzipped_ans = _.find(question.answers, ans => ans.zipper_answer_id === zsa.id)
                    zsa.zipper_answer_id = zsa.id
                    zsa.id = unzipped_ans.id
                  }
                  return zsa
                },
              )
            }
            if (zipper_question.text[sample.id]) {
              question.text[sample.id] = zipper_question.text[sample.id]
            }
            if (_.any(question.selected_answers)) {
              question.has_been_seen = true
            }
          }
          zipper_question.unzipped_questions.push(question)
        })
      })
    })
  },
}

// this must be done to avoid circular type dependencies
surveyHelperModule.elements = surveyElements

//####################################
const surveyHelper = () => surveyHelperModule
export default surveyHelper

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}

declare global {
  type SurveyHelper = typeof surveyHelperModule
}

// @ts-ignore
window.SurveyHelper = surveyHelperModule
