import * as _ from "lodash"

class CheckboxQuestion extends Taker.BaseQuestion
  @icon: "fa fa-check-square-o"
  @display_name: "Multiple Selection"
  @question_type: "checkbox"
  @has_multiple_selections: true

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.CheckboxQuestion = CheckboxQuestion
