class ChoiceModelQuestion extends Taker.BaseQuestion
  @is_choice_model: true

  # guard for builder previewer to not display until builderHelper available so that changes can be ignored
  showProfile: (profile_tracking_value, index, isBuilderPreview, builderHelper) ->
    return profile_tracking_value == index && (!isBuilderPreview || (isBuilderPreview && builderHelper))

  constructor: (data) ->
    defaults = {}
    _.merge(defaults, data)
    super defaults

window.Taker.ChoiceModelQuestion = ChoiceModelQuestion
