class MultipleResponseGridQuestion extends Taker.GridQuestion
  @icon: "fa fa-table"
  @display_name: "Multiple Response Grid"
  @question_type: "multiple_response_grid"

  validate: ->
    @error_messages = []
    if @validations && @is_visible
      _.each @validations, (rule) =>
        if rule.type is "required"
          if !(_.every @groups, (group) -> group.selected_answers && group.selected_answers.length > 0)
            @error_messages.push {type: "grid_required"}
    @error_messages.length is 0

  handleExclusiveResponses: (group, answer) ->
    if answer.config && answer.config.exclusive #I am adding an exclusive so remove everything else
      group.selected_answers = []
    else
      exclusive_responses = _.filter @answers, {config: {exclusive: true}}
      _.each exclusive_responses, (response) =>
        obj = _.find(group.selected_answers, {id: response.id})
        group.selected_answers = _.without(group.selected_answers, obj) if obj

  recordAnswer: (group, answer) ->
    group.selected_answers = []  unless group.selected_answers
    obj = _.find(group.selected_answers, {id: answer.id})
    if obj
      group.selected_answers = _.without(group.selected_answers, obj)
    else
      @handleExclusiveResponses(group, answer)
      group.selected_answers.push @createSelectedAnswer(answer)

    @validate() if @error_messages and @error_messages.length > 0
    @publishAnswerSelectedEvent()

  isAnswerSelected: (group, answer) ->
    if answer && group &&  _.find(group.selected_answers, {id: answer.id}) then true else false


  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.MultipleResponseGridQuestion = MultipleResponseGridQuestion
