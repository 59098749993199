class PairedComparison extends Taker.DiscrimQuestion
  @icon: "fa fa-dot-circle-o"
  @display_name: "Paired Comparison"
  @question_type: "discrim:paired"
  @file_name: "paired"

  recordAnswer: (answer) ->
    @selected_answers = [{id: answer.id, answered_at: Date.now()}]
    @publishAnswerSelectedEvent()

  determineCorrectness: (surveyHelper) ->
    grouped_samples = _.groupBy(surveyHelper.samples, 'id')
    should_be_same = Object.keys(grouped_samples).length == 1
    selected_ans = @selected_answers[0]

    answer_source = _.find(@answers, (ans) ->
      ans.id.toString() == selected_ans.id.toString()
    )

    if should_be_same
      @is_correct = answer_source.value == "same"
    else
      @is_correct = answer_source.value == "different"

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.PairedComparison = PairedComparison
