import { color } from "html2canvas/dist/types/css/types/color";

var targetCanvas = function ($filter, $timeout) {
  "ngInject";
  return {
    restrict: "E",
    scope: {
      answers: "=",
      samples: "=",
      config: "=",
      ngModel: "=",
      initIndex: "=",
      onValueChange: "&",
    },
    templateUrl: "survey_taker/question_types/target_canvas.html",
    link: function (scope, element, attrs) {
      const canvas = <HTMLCanvasElement>element.find("#target-canvas")[0];
      const ctx = canvas.getContext("2d");

      let margin: number = 50;
      let answers = [].concat(...Object.values(scope.answers ?? [])); //[{sample_id: 1, x: 300, y:300, score: 100 }]
      answers.forEach(a => {
        a.x += 0;
        a.y += 0;
      });
      let samples = Object.values(scope.samples ?? []);
      if (samples.length == 0) samples = [{}]; //no samples needs 1 subject
      else if (samples.length == 1 || scope.config.zipper_merge) samples = samples;
      else if (scope.config.zipper) samples = samples.filter(s => s["id"] == this.sampleId);
      else samples = [{}]; //multi-sample with out zippering needs 1 subject

      let scale: number;
      let step: number;
      let targetColors: string[];
      let borderColor: string;
      let dartColor: string;
      let size: number; // size of the target
      let dartRadius: number;
      let targetRadius: number;
      let bandSize: number;
      let borderWidth: number;

      var drawTarget = function () {
        margin = 50;
        scale = scope.config.max - scope.config.min; // convert x,y coordinates where [scale] is a bullseye and 0 is a miss
        step = scope.config.step; // round answers to the nearest
        var vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0); // https://stackoverflow.com/questions/1248081/how-to-get-the-browser-viewport-dimensions
        size = Math.min(scope.config.width, vw - margin - margin - 50);
        targetColors = scope.config.target_colors;
        borderColor = "#A8A8A8"; //scope.borderColor;
        dartColor = scope.config.dart_color;
        dartRadius = size / 75;
        targetRadius = size / 2;
        bandSize = targetRadius / targetColors.length;
        borderWidth = 1;

        canvas.width = size + margin + margin;
        canvas.height = size + margin + margin;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // for (let r = targetRadius, colorIndex = 0; r > 1; r -= bandSize, colorIndex += 1) {
        //
        for (let colorIndex = 0; colorIndex < targetColors.length; colorIndex++) {
          var r = targetRadius - bandSize * colorIndex;
          //draw the colored rings
          ctx.fillStyle = targetColors[colorIndex];
          ctx.beginPath();
          ctx.arc(targetRadius + margin, targetRadius + margin, r, 0, Math.PI * 2);
          ctx.closePath();
          ctx.fill();

          //draw legend
          if (scope.config.show_legend) {
            ctx.font = `${Math.min(18, bandSize - 3)}px Lato`;

            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillStyle = invertColor(targetColors[colorIndex]);
            var labelx = margin + bandSize * (colorIndex + 1) - bandSize / 2;
            var labely = margin + targetRadius;
            var score = Math.round(calculateScore(labelx, labely)).toString();

            if (colorIndex < targetColors.length - 1) {
              var is_odd = colorIndex % 2;
              if (is_odd || bandSize > 15 || true) {
                ctx.fillText(score, labelx, labely); //draw left
                ctx.fillText(score, targetRadius + margin, labelx); //draw up
              }
              if (!is_odd || bandSize > 15 || true) {
                var distance_to_other_side = (r - bandSize) * 2 + bandSize;
                ctx.fillText(score, labelx + distance_to_other_side, labely); //draw right
                ctx.fillText(score, targetRadius + margin, labelx + distance_to_other_side); //draw up
              }
            } //draw the bullseye
            else {
              ctx.fillText(
                calculateScore(targetRadius + margin, targetRadius + margin).toString(),
                targetRadius + margin,
                targetRadius + margin,
              );
            }
          }

          //draw the border
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;
          ctx.shadowBlur = borderWidth;
          ctx.shadowColor = borderColor;
          ctx.lineWidth = borderWidth;
          ctx.strokeStyle = borderColor;
          ctx.stroke();
        }

        //draw each dart
        answers.forEach(a => {
          var sample = samples.find(s => s["id"] == a.sample_id);

          ctx.fillStyle = scope.config.use_sample_colors ? sample["line_color"] : dartColor;
          ctx.beginPath();
          ctx.arc(a.x, a.y, dartRadius, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.fill();

          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;
          ctx.shadowBlur = borderWidth;
          ctx.shadowColor = borderColor;
          ctx.lineWidth = borderWidth;
          ctx.strokeStyle = borderColor;
          ctx.stroke();

          var tooltip = "";
          if (samples.length > 1) tooltip += $filter("sampleDisplayLabel")(sample?.["id"]);
          // if (samples[0]["id"]) tooltip += $filter('sampleDisplayLabel')(sample?.["id"]);
          if (tooltip.length > 0 && scope.config.show_answer_value) tooltip += " - ";
          if (scope.config.show_answer_value) tooltip += Math.round(a.score * 10) / 10;
          drawLabel(a.x, a.y, tooltip, sample["line_color"], a.answered_at);
        });

        //set display text
        ctx.translate(0.5, 0.5);
        scope.initIndex = samples.findIndex(s => answers.every(a => s["id"] != a.sample_id));
      };

      var invertColor = function (hex) {
        var b, g, r;
        if (hex.indexOf("#") === 0) {
          hex = hex.slice(1);
        }
        if (new RegExp("^[0-9a-fA-F]+$").test(hex)) {
          if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
          }

          r = parseInt(hex.slice(0, 2), 16);
          g = parseInt(hex.slice(2, 4), 16);
          b = parseInt(hex.slice(4, 6), 16);
          if (r * 0.299 + g * 0.587 + b * 0.114 > 80) {
            return "#000000";
          } else {
            return "#FFFFFF";
          }
        } else {
          return "#000000";
        }
      };

      var drawLabel = function (dart_x, dart_y, text, color, answered_at) {
        if (!text || text.lenght == 0) return;
        var x = dart_x;
        var y = dart_y;
        var radius = 5;
        var width = 15 + text.length * 6;
        var height = 25;

        if (x < margin) {
          //in the upper margin
          x += dartRadius + 5; //draw the label on right of the dart
          y -= height / 2;
        } else if (x > size) {
          //in the lower margin
          x -= dartRadius + width + 5; //draw the label under teh dart
          y -= height / 2;
        } else {
          x -= width / 2; // draw the label centered
          if (y < margin) {
            //in the upper margin
            y += dartRadius + 5; //draw the label under the dart
          } //in the lower margin
          else {
            y -= dartRadius + height + 5; //draw the label on top of teh dart
            var other_answers = answers.filter(a => {
              return a.answered_at < answered_at;
            });
            var conflict_count = other_answers.filter(o => {
              return distanceFromPoint(dart_x, dart_y, o.x, o.y) <= width;
            }).length;
            y -= (height + 5) * conflict_count;
          }
        }

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#000";
        ctx.fillStyle = "#fff";
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
        ctx.fill();
        //
        ctx.font = "15px Lato";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = scope.config.use_sample_colors ? color : "#000";

        ctx.fillText(text, x + width / 2, y + height / 2);
      };

      var targetClicked = function (event) {
        var x = event.offsetX;
        var y = event.offsetY;

        if (distanceFromPoint(x, y, targetRadius + margin, targetRadius + margin) > targetRadius + margin) {
          return; //they didnt hit the target
        }
        if (answers.length < samples.length) {
          recordAnswer(x, y);
        } else {
          //move the nearest dart
          if (answers.length == 0) return;
          var nearestDart = answers.reduce((a, b) =>
            distanceFromPoint(x, y, a.x, a.y) < distanceFromPoint(x, y, b.x, b.y) ? a : b,
          );
          moveDart(x, y, nearestDart);
        }
      };

      var moveDart = function (x, y, answer) {
        var isClicked = distanceFromPoint(x, y, answer.x, answer.y) <= dartRadius + 5;

        if (isClicked) {
          //remove the dart if clicked
          answer._destroy = true;
          answers = answers.filter(function (a) {
            return a.sample_id != answer.sample_id;
          });
        } //move the dart
        else {
          answer.x = x;
          answer.y = y;
          answer.score = calculateScore(x, y);
        }

        scope.$apply(() => (scope.ngModel = answer));
        scope.onValueChange();
        drawTarget();
        return isClicked;
      };

      var distanceFromPoint = function (x, y, point_x, point_y) {
        var distance = Math.sqrt((point_x - x) * (point_x - x) + (point_y - y) * (point_y - y));
        return distance;
      };

      var calculateScore = function (x, y) {
        var distanceFromMiddle = distanceFromPoint(x, y, targetRadius + margin, targetRadius + margin);
        var percentFromEdge = 1 - distanceFromMiddle / targetRadius;
        var score = scale * percentFromEdge + parseInt(scope.config.min) + 0.0001; //pixels are weird, 1.4999999999999999 rounds down and thats bad
        var normalized_step = step < 0 ? 1 / step : step;

        var roundedScore = Math.round(score / normalized_step) * normalized_step;

        return Math.min(Math.max(roundedScore, scope.config.min), scope.config.max);
      };

      var recordAnswer = function (x, y) {
        var current_sample = samples.find(s => answers.every(a => s["id"] != a.sample_id));
        if (current_sample == null) {
          return;
        }

        var answer = { sample_id: current_sample["id"], x: x, y: y, score: calculateScore(x, y) };
        answers.push(answer);
        scope.$apply(
          () => (scope.ngModel = { sample_id: current_sample["id"], x: x, y: y, score: calculateScore(x, y) }),
        );
        scope.onValueChange();

        drawTarget();
        return answer;
      };

      canvas.addEventListener("click", targetClicked);
      $timeout(drawTarget);

      scope.$watch(
        "config",
        (newVal, oldVal) => {
          if (newVal != oldVal) {
            drawTarget();
          }
        },
        true,
      );

      scope.$watch(
        "answers",
        (newVal, oldVal) => {
          if (newVal != oldVal) {
            answers = [].concat(...Object.values(newVal ?? []));
            drawTarget();
          }
        },
        true,
      );

      window.onresize = e => {
        drawTarget();
      };
    },
  };
};

export default targetCanvas;
