class Nafc extends Taker.DiscrimQuestion
  @icon: "fa fa-check-square-o"
  @display_name: "nAFC"
  @question_type: "discrim:nafc"
  @file_name: "nafc"
  
  recordAnswer: (sample) ->
    @selected_answers[0] = {serving_sample_id: sample.serving_sample_id, sample_id: sample.id, answered_at: Date.now()}
    @validate() if @error_messages and @error_messages.length > 0
    @publishAnswerSelectedEvent()

  isAnswerSelected: (sample) ->
    _.any(@selected_answers, (ans) -> ans.serving_sample_id == sample.serving_sample_id)

  determineCorrectness: (surveyHelper) ->
    samples = surveyHelper.samples
    correct_sample = _.find(samples,{is_control: true })
    @is_correct = @selected_answers[0].sample_id != correct_sample.id

  constructor: (data) ->
    init_data = {}
    _.merge(init_data, data)
    super init_data

window.Taker.Nafc = Nafc
